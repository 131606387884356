import React from 'react'

import { Box } from '@mui/material'

import Button from '../../../Button/Button.component'
import ResponsiveDisplayWrapper from '../../../ResponsiveDisplayWrapper/ResponsiveDisplayWrapper.component'

import router from '../../../../../router/functions/router.functions'
import routes from '../../../../../router/routes.dictionary'
import styles from './AuthButtons.styles'
import { AuthButtonsProps } from './AuthButtons.types'

const AuthButtons: React.FC<AuthButtonsProps> = (props) => {
  const isLoginPageActive = props.currentPage.path === routes.login.path
  const navigate = router.navigate()

  return (
    <Box sx={styles.buttonContainer}>
      <ResponsiveDisplayWrapper isDesktop={true}>
        <Button
          buttonType="tertiary"
          text={routes.login.name}
          onClick={() => navigate(routes.login.path)}
          isNav
          isActiveNav={isLoginPageActive}
          style={styles.loginButton}
        />
      </ResponsiveDisplayWrapper>

      <ResponsiveDisplayWrapper style={styles.buttonWrapper} isDesktop={false}>
        <Button
          buttonType="tertiary"
          text={routes.login.name}
          onClick={() => navigate(routes.login.path)}
          isNav
          isActiveNav={isLoginPageActive}
          style={styles.loginButtonMobile}
          outline
        />
      </ResponsiveDisplayWrapper>

      <ResponsiveDisplayWrapper isDesktop={true}>
        <Button buttonType="primary" text={routes.signUp.name} onClick={() => navigate(routes.signUp.path)} />
      </ResponsiveDisplayWrapper>

      <ResponsiveDisplayWrapper style={styles.buttonWrapper} isDesktop={false}>
        <Button
          style={styles.signUpButtonMobile}
          containerStyle={styles.signUpButtonContainerMobile}
          buttonType="primary"
          text={routes.signUp.name}
          onClick={() => navigate(routes.signUp.path)}
        />
      </ResponsiveDisplayWrapper>
    </Box>
  )
}

export default AuthButtons
