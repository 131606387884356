import { useCallback, useEffect, useMemo, useState } from 'react'

import { ArrowForwardRounded, ExpandMore } from '@mui/icons-material'
import { Box, Divider, StepLabel as MuiStepLabel, Step, Stepper, Typography } from '@mui/material'

import Accordion from '../../../../shared/components/Accordion/Accordion.component'
import FlightItineraryHeader from '../../../../shared/components/FlightItineraryHeader/FlightItineraryHeader.component'
import Link from '../../../../shared/components/Link/Link.component'
import StepLabel from '../StepLabel/StepLabel.component'

import { cancelFlightItinerary } from '../../../../redux/states/flights/cancelFlightItinerary/cancelFlightItinerary.slice'
import { CancelFlightItineraryRequestPayload } from '../../../../redux/states/flights/cancelFlightItinerary/cancelFlightItinerary.types'
import {
  FlightDetails,
  FlightItinerary
} from '../../../../redux/states/flights/getAllFlightItineraries/getAllFlightItineraries.types'
import { getUpdatedFlightOffer } from '../../../../redux/states/flights/getUpdatedFlightOffer/getUpdatedFlightOffer.slice'
import { GetUpdatedFlightOfferRequestPayload } from '../../../../redux/states/flights/getUpdatedFlightOffer/getUpdatedFlightOffer.types'
import { RootState } from '../../../../redux/store/store.types'
import router from '../../../../router/functions/router.functions'
import routes from '../../../../router/routes.dictionary'
import { AccordionButtonProps, AccordionFooterProps } from '../../../../shared/components/Accordion/Accordion.types'
import Modal from '../../../../shared/components/Modal/Modal.functions'
import { PaginatedRenderProps } from '../../../../shared/components/PaginatedPage/PaginatedPage.types'
import status from '../../../../shared/dictionaries/status.dictionaries'
import date, { dateTimeFormats, formatDuration } from '../../../../shared/functions/Date/date.functions'
import { joinStringWithBullet, joinStringWithDash } from '../../../../shared/functions/String/string.functions'
import { useAppDispatch, useAppSelector } from '../../../../shared/hooks/redux.hooks'
import { getFormattedCabinClass } from '../../Trips.functions'
import styles from './TripDetailsAccordion.styles'

const TripDetailsAccordion = (props: PaginatedRenderProps) => {
  const refreshFlightItineraries = props.refreshData
  const flightItinerary = props.item as FlightItinerary
  const index = props.index
  const listLength = props.listLength
  const isCompletedTrip = date(
    flightItinerary.itinerary.legs[flightItinerary.itinerary.legs.length - 1].travelDate
  ).isBefore(date().currentDate)

  const [expanded, setExpanded] = useState<{ [key: string]: boolean }>({})
  const [bookFlightLoadingButton, setBookFlightLoadingButton] = useState<string | null>(null)
  const [cancelFlightLoadingButton, setCancelFlightLoadingButton] = useState<string | null>(null)
  const [isFirstAccordionExpanded, setIsFirstAccordionExpanded] = useState(
    index === 0 && listLength === 1 && !isCompletedTrip
  )

  const { loading: cancelFlightItineraryLoading } = useAppSelector((state: RootState) => state.cancelFlightItinerary)

  const { loading: getAllFlightItinerariesLoading } = useAppSelector(
    (state: RootState) => state.getAllFlightItineraries
  )

  const { success: getUpdatedFlightOfferSuccess, error: getUpdatedFlightOfferError } = useAppSelector(
    (state: RootState) => state.getUpdatedFlightOffer
  )

  const navigate = router.navigate()
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (index === 0 && listLength !== 1) {
      setIsFirstAccordionExpanded(false)
    }
  }, [index, listLength])

  useEffect(() => {
    if (getUpdatedFlightOfferSuccess || getUpdatedFlightOfferError) {
      setBookFlightLoadingButton(null)
    }
  }, [getUpdatedFlightOfferSuccess, getUpdatedFlightOfferError])

  const handleAccordionExpansion = (isExpanded: boolean) => {
    setExpanded((prev) => ({
      ...prev,
      [flightItinerary.id]: isExpanded
    }))
  }

  const onBookFlight = useCallback(() => {
    setBookFlightLoadingButton(flightItinerary.id.toString())

    const payload: GetUpdatedFlightOfferRequestPayload = {
      request: {
        id: flightItinerary.id.toString()
      },

      onSuccess: (success) => {
        if (success.data.offer) {
          navigate(routes.tripSummary.path, {
            state: { fare: success.data.offer, isBookingFlight: true, itinerary: flightItinerary }
          })
        }
      },

      onError: () => {
        Modal.show({
          title: 'Offer Expired',
          subtitle: 'We were unable to find a new offer for your flight. Please search for a new offer manually.',
          primaryButton: {
            label: 'Search for new offer',
            onClick: () => {
              navigate(routes.flightSearchResults.path, {
                state: {
                  existingFlightItinerary: flightItinerary,
                  isBookingFlight: true
                }
              })
            }
          },
          secondaryButton: {
            label: 'Dismiss'
          }
        })

        setBookFlightLoadingButton(null)
      }
    }

    dispatch(getUpdatedFlightOffer(payload))
  }, [flightItinerary, dispatch, navigate])

  const getAccordionHeader = () => {
    const tripPrice = parseFloat(flightItinerary.itinerary.totalFlightPrice).toFixed(2)

    return {
      mainContent: <FlightItineraryHeader flightItinerary={flightItinerary} />,
      secondaryContent: (
        <Box sx={styles.accordionHeaderSecondaryContent}>
          <Typography sx={styles.accordionHeaderSecondaryContentTitleText}>${tripPrice}</Typography>
          <Typography sx={styles.accordionHeaderSecondaryContentSubtitleText}>Flight Price</Typography>
        </Box>
      )
    }
  }

  const preventExpandChange = useMemo(() => {
    return flightItinerary.itinerary.associatedFlights.length === 0
  }, [flightItinerary.itinerary.associatedFlights])

  const renderAssociatedFlight = (associatedFlightDetails: FlightDetails) => {
    const steps = [
      {
        label: `${date(associatedFlightDetails.departureTime).format(`${dateTimeFormats.date.long} ${dateTimeFormats.time.short}`)}`,
        description: `Depart from ${associatedFlightDetails.origin.airport.name} (${associatedFlightDetails.origin.airport.iataCode})`
      },
      {
        label: `${date(associatedFlightDetails.arrivalTime).format(`${dateTimeFormats.date.long} ${dateTimeFormats.time.short}`)}`,
        description: `Arrive at ${associatedFlightDetails.destination.airport.name} (${associatedFlightDetails.destination.airport.iataCode})`
      }
    ]

    const additionalDetails = [
      {
        label: 'Type',
        description: getFormattedCabinClass(flightItinerary.itinerary.cabinClass)
      },
      {
        label: 'Airline',
        description: associatedFlightDetails.airline.name
      },
      {
        label: 'Plane',
        description: associatedFlightDetails.aircraftName ?? 'N/A'
      },
      {
        label: 'Flight Number',
        description: associatedFlightDetails.flightNumber
      }
    ]

    const flightLegSummary = () => {
      const getDateSummary = () => {
        const departureDate = date(associatedFlightDetails.departureTime).format(dateTimeFormats.date.medium)
        const arrivalDate = date(associatedFlightDetails.arrivalTime).format(dateTimeFormats.date.medium)

        if (departureDate === arrivalDate) {
          return joinStringWithBullet([
            `${date(associatedFlightDetails.departureTime).format(dateTimeFormats.date.long)}`,
            joinStringWithDash([
              `${date(associatedFlightDetails.departureTime).format(dateTimeFormats.time.short)}`,
              `${date(associatedFlightDetails.arrivalTime).format(dateTimeFormats.time.short)}`
            ])
          ])
        } else {
          const departureTime = joinStringWithBullet([
            `${date(associatedFlightDetails.departureTime).format(dateTimeFormats.date.long)}`,
            `${date(associatedFlightDetails.departureTime).format(dateTimeFormats.time.short)}`
          ])

          const arrivalTime = joinStringWithBullet([
            `${date(associatedFlightDetails.arrivalTime).format(dateTimeFormats.date.long)}`,
            `${date(associatedFlightDetails.arrivalTime).format(dateTimeFormats.time.short)}`
          ])

          return joinStringWithDash([departureTime, arrivalTime])
        }
      }

      const tripDuration = formatDuration(associatedFlightDetails.duration)

      const tripRoute = `${associatedFlightDetails.origin.airport.iataCode} → ${associatedFlightDetails.destination.airport.iataCode}`

      return (
        <Box sx={styles.legSummaryContainer}>
          <Box sx={styles.airlineLogoContainer}>
            <img
              src={associatedFlightDetails.airline.logo}
              alt={associatedFlightDetails.airline.name}
              style={styles.airlineLogo}
            />
          </Box>

          <Box sx={styles.legSummaryContentContainer}>
            <Box sx={styles.legSummaryContent}>
              <Typography sx={styles.legSummaryContentTitleText}>{getDateSummary()}</Typography>
              <Typography sx={styles.legSummaryContentSubtitleText}>{associatedFlightDetails.airline.name}</Typography>
            </Box>

            <Box sx={styles.legSummaryContent}>
              <Typography sx={styles.legSummaryContentTitleText}>{tripDuration}</Typography>
              <Typography sx={styles.legSummaryContentSubtitleText}>{tripRoute}</Typography>
            </Box>
          </Box>
        </Box>
      )
    }

    const flightLegSteps = () => {
      return (
        <Box sx={styles.legDetails}>
          <Stepper activeStep={-1} orientation="vertical">
            {steps.map((step) => (
              <Step key={step.label}>
                <MuiStepLabel
                  StepIconComponent={StepLabel}
                  optional={<Typography sx={styles.legStepDescriptionText}>{step.description}</Typography>}
                >
                  <Typography sx={styles.legStepLabelText}>{step.label}</Typography>
                </MuiStepLabel>
              </Step>
            ))}
          </Stepper>
        </Box>
      )
    }

    const renderAdditionalFlightDetails = () => {
      return (
        <Box sx={styles.additionalDetailsContainer}>
          {additionalDetails.map((detail) => (
            <Box sx={styles.additionalDetailContainer}>
              <Typography sx={styles.additionalDetailLabelText}>{detail.label}</Typography>
              <Typography sx={styles.additionalDetailDescriptionText}>{detail.description}</Typography>
            </Box>
          ))}
        </Box>
      )
    }

    return (
      <Box sx={styles.legDetailsContainer}>
        {flightLegSummary()}
        {flightLegSteps()}
        {renderAdditionalFlightDetails()}
      </Box>
    )
  }

  const renderDetails = () => {
    const validAssociatedFlight = flightItinerary.itinerary.associatedFlights[0]

    if (!validAssociatedFlight) {
      return null
    } else {
      return (
        <Box sx={styles.flightDetailsContainer}>
          {validAssociatedFlight.flightDetails.map((associatedFlightDetails) => {
            const isLastAssociatedFlightLeg =
              validAssociatedFlight.flightDetails.indexOf(associatedFlightDetails) ===
              validAssociatedFlight.flightDetails.length - 1

            return (
              <Box>
                {renderAssociatedFlight(associatedFlightDetails)}
                {!isLastAssociatedFlightLeg && <Divider sx={styles.divider} />}
              </Box>
            )
          })}
        </Box>
      )
    }
  }

  const renderFooter = () => {
    const isBookFlightDisabled = flightItinerary.itinerary.legs.some((leg) => {
      const isInvalidStatus = leg.offerRequestsDetails.some((offerRequest) =>
        offerRequest.itemRequestDetails.some(
          (detail) =>
            detail.status.name === status.itemRequestStatus.pendingAcceptanceByTraveler ||
            detail.status.name === status.itemRequestStatus.reassignedByShopper
        )
      )

      return isInvalidStatus || cancelFlightItineraryLoading
    })

    const isCancelTripDisabled = () => {
      const lastLeg = flightItinerary.itinerary.legs[flightItinerary.itinerary.legs.length - 1]

      let isTripInCancellableState =
        flightItinerary.status.name === status.flightStatus.interestedInFlight ||
        flightItinerary.status.name === status.flightStatus.flightBooked

      if (date().isAfter(lastLeg.travelDate)) {
        isTripInCancellableState = false
      }

      return !isTripInCancellableState || bookFlightLoadingButton === flightItinerary.id.toString()
    }

    const isCancelTripButtonVisible =
      flightItinerary.status.name !== status.flightStatus.flightBooked &&
      flightItinerary.status.name !== status.flightStatus.flightCancelledAfterPayment &&
      flightItinerary.status.name !== status.flightStatus.flightBookingCancelledBeforePayment

    const handleDetailsButtonPress = (id: number) => {
      setExpanded((prev) => ({
        ...prev,
        [id]: !prev[id]
      }))
    }

    const handleCancelTrip = () => {
      const payload: CancelFlightItineraryRequestPayload = {
        request: {
          id: flightItinerary.id.toString()
        },

        onSuccess: () => {
          refreshFlightItineraries && refreshFlightItineraries(() => setCancelFlightLoadingButton(null))
        }
      }

      Modal.show({
        title: 'Cancel Trip',
        body: 'Are you sure you want to cancel this trip? All associated offers will be removed.',
        primaryButton: {
          label: 'Confirm',
          onClick: () => {
            dispatch(cancelFlightItinerary(payload))
            setCancelFlightLoadingButton(flightItinerary.id.toString())
          }
        },
        secondaryButton: {
          label: 'Dismiss'
        }
      })
    }

    const accordionFooterButtons: AccordionButtonProps[] = [
      {
        text: 'Details',
        icon: (
          <ExpandMore
            style={{
              transform: expanded[flightItinerary.id] ? 'rotate(180deg)' : 'rotate(0deg)',
              transition: 'transform 0.3s'
            }}
          />
        ),
        disabled: preventExpandChange,
        iconPosition: 'end',
        onClick: () => handleDetailsButtonPress(flightItinerary.id),
        expandAccordion: true,
        buttonType: 'tertiary'
      }
    ]

    if (isCancelTripButtonVisible) {
      accordionFooterButtons.push({
        text: 'Cancel Trip',
        buttonType: 'secondary',
        onClick: handleCancelTrip,
        loading:
          cancelFlightLoadingButton === flightItinerary.id.toString() &&
          (cancelFlightItineraryLoading || getAllFlightItinerariesLoading),
        disabled: isCancelTripDisabled(),
        tooltipText: isCancelTripDisabled() ? 'Trip is not in a cancellable state.' : ''
      })
    }

    const isBookFlightButtonVisible =
      flightItinerary.status.name !== status.flightStatus.flightBooked &&
      flightItinerary.status.name !== status.flightStatus.flightBookingCancelledBeforePayment &&
      flightItinerary.status.name !== status.flightStatus.flightCancelledAfterPayment

    if (isBookFlightButtonVisible) {
      accordionFooterButtons.push({
        text: 'Book Flight',
        onClick: onBookFlight,
        buttonType: 'primary',
        loading: bookFlightLoadingButton === flightItinerary.id.toString(),
        disabled: isBookFlightDisabled,
        tooltipText: isBookFlightDisabled
          ? 'Must action all item requests before you can book the flight.'
          : 'Must book flight before you can buy the items on Amazon.'
      })
    }

    const accordionFooterMainContent = () => {
      const numberOfOfferRequests = flightItinerary.itinerary.legs.reduce((acc, leg) => {
        leg.offerRequestsDetails.forEach((offerRequest) => {
          acc += offerRequest.itemRequestDetails.length
        })

        return acc
      }, 0)

      const isTripCancelled =
        flightItinerary.status.name === status.flightStatus.flightBookingCancelledBeforePayment ||
        flightItinerary.status.name === status.flightStatus.flightCancelledAfterPayment

      if (isTripCancelled) {
        return <Typography sx={styles.flightCancelledNoticeText}>Trip Cancelled</Typography>
      } else {
        return (
          <Box sx={styles.accordionFooterMainContent}>
            <Typography sx={styles.accordionFooterMainContentText}>
              You have {numberOfOfferRequests} Request{numberOfOfferRequests === 1 ? '' : 's'}
              <Link href={routes.requests.path} showColor style={styles.accordionFooterMainContentLink}>
                Go to Requests
              </Link>
            </Typography>

            <ArrowForwardRounded sx={styles.goToRequestsArrowIcon} onClick={() => navigate(routes.requests.path)} />
          </Box>
        )
      }
    }

    const accordionFooter: AccordionFooterProps = {
      mainContent: accordionFooterMainContent(),
      buttons: accordionFooterButtons
    }

    return accordionFooter
  }

  return (
    <Accordion
      header={getAccordionHeader()}
      body={renderDetails()}
      preventExpandChange={preventExpandChange}
      isExpanded={isFirstAccordionExpanded}
      noExpandIcon
      handleExpansion={handleAccordionExpansion}
      footer={renderFooter()}
      key={flightItinerary.id}
    />
  )
}

export default TripDetailsAccordion
