import { CSSProperties } from 'react'

import { SxProps } from '@mui/material'

import { mobileXPadding, mobileYPadding } from '../../../layouts/MainLayout/MainLayout.constants'
import { fullWidthIgnorePadding } from '../../functions/Styling/styling.functions'
import theme from '../../styles/themes/default.theme'

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%'
  },

  content: {
    width: '100%'
  },

  stepperContainer: (noBackground: boolean, areArrowsPresent: boolean) => ({
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: areArrowsPresent ? 'space-between' : 'center',
    paddingTop: noBackground ? 0 : theme.spacing(5),
    paddingBottom: noBackground ? 0 : theme.spacing(5),
    backgroundColor: noBackground ? 'transparent' : theme.palette.grey[100],
    paddingLeft: areArrowsPresent ? theme.spacing(10) : 0,
    paddingRight: areArrowsPresent ? theme.spacing(10) : 0,
    borderTop: noBackground ? 'none' : `1px solid ${theme.palette.grey[200]}`,
    borderBottom: noBackground ? 'none' : `1px solid ${theme.palette.grey[200]}`,
    [theme.breakpoints.down('md')]: {
      width: fullWidthIgnorePadding(mobileXPadding),
      marginLeft: theme.spacing(-mobileXPadding),
      marginTop: theme.spacing(-mobileYPadding),
      justifyContent: 'flex-start',
      paddingX: theme.spacing(mobileXPadding),
      paddingY: theme.spacing(2)
    }
  }),

  stepper: (stepperWidth: string) => ({
    width: stepperWidth,

    '& .MuiStep-root': {
      padding: '0 16px'
    },

    '& .active-step .MuiStepLabel-label': {
      color: '#1976d2',
      fontWeight: 'bold'
    },

    '& .inactive-step .MuiStepLabel-label': {
      color: '#bdbdbd',
      fontWeight: 'normal'
    },

    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  }),

  stepLabelText: {
    marginLeft: theme.spacing(1),
    [theme.breakpoints.down('md')]: {
      fontWeight: theme.typography.fontWeightBold,
      fontSize: theme.typography.subtitle1.fontSize
    }
  },

  step: (stepsLength: number) => ({
    maxWidth: `${100 / stepsLength}%`
  }),

  stepDescriptionText: {
    fontSize: theme.typography.subtitle2.fontSize,
    fontWeight: theme.typography.fontWeightBold,
    textAlign: 'left',
    color: theme.palette.grey[500],
    marginLeft: theme.spacing(1)
  } as CSSProperties,

  navButtonContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '40px',
    width: '40px',
    borderRadius: '50%',
    backgroundColor: theme.palette.common.white,
    '&:hover': {
      backgroundColor: theme.palette.grey[50]
    }
  },

  navButton: {
    height: '25px',
    width: '25px',
    borderRadius: '50%',
    color: theme.palette.primary.main
  },

  stepOfStepsText: {
    color: theme.palette.grey[600],
    fontSize: theme.typography.subtitle1.fontSize,
    fontWeight: theme.typography.fontWeightBold,
    textAlign: 'left',
    marginLeft: theme.spacing(1)
  } as SxProps
}

export default styles
