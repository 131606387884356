import React from 'react'

import CloseIcon from '@mui/icons-material/Close'
import MenuIcon from '@mui/icons-material/Menu'
import { Box, IconButton, Menu, MenuItem, Typography } from '@mui/material'

import router from '../../../../../router/functions/router.functions'
import styles from './OverFlowMenu.styles'
import { OverFlowMenuProps } from './OverFlowMenu.types'

const OverFlowMenu: React.FC<OverFlowMenuProps> = (props) => {
  const { pages } = props

  const navigate = router.navigate()
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null)

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget)
  }

  const handleCloseNavMenu = () => {
    setAnchorElNav(null)
  }

  return (
    <Box sx={styles.menuContainer}>
      <IconButton
        size="large"
        aria-label="account of current user"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={handleOpenNavMenu}
        color="inherit"
        sx={styles.menuIcon}
      >
        {anchorElNav === null ? <MenuIcon /> : <CloseIcon />}
      </IconButton>

      <Menu
        id="menu-appbar"
        anchorEl={anchorElNav}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        keepMounted
        open={Boolean(anchorElNav)}
        onClose={handleCloseNavMenu}
        sx={styles.menuContentContainer}
      >
        {pages.map((page, index) => (
          <MenuItem
            key={page?.name}
            divider={index < pages.length - 1}
            onClick={() => {
              handleCloseNavMenu()
              navigate(page?.path)
            }}
          >
            <Typography textAlign="center">{page?.name}</Typography>
          </MenuItem>
        ))}
      </Menu>
    </Box>
  )
}

export default OverFlowMenu
