import { CSSProperties } from 'react'

import { SxProps } from '@mui/material'

import theme from '../../shared/styles/themes/default.theme'

export default {
  container: {
    display: 'flex',
    gap: theme.spacing(2),
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '100%',
    minHeight: '100vh',
    paddingBottom: theme.spacing(5),
    [theme.breakpoints.down('md')]: {
      gap: 0
    }
  },

  stepHeader: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    width: '100%',
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(3),
    paddingLeft: theme.spacing(10),
    [theme.breakpoints.down('md')]: {
      paddingLeft: 0,
      paddingBottom: theme.spacing(3),
      paddingTop: theme.spacing(4)
    }
  },

  title: {
    textAlign: 'left',
    fontWeight: theme.typography.fontWeightBold
  } as CSSProperties,

  subtitle: {
    textAlign: 'left',
    color: theme.palette.text.secondary
  },

  cartContent: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    paddingLeft: theme.spacing(10),
    paddingRight: theme.spacing(10),
    paddingBottom: theme.spacing(5),
    flexGrow: 1,
    [theme.breakpoints.down('md')]: {
      paddingLeft: 0,
      paddingRight: 0
    }
  } as SxProps,

  stepContent: {
    flexDirection: 'column',
    display: 'flex',
    textAlign: 'left',
    alignItems: 'center',
    justifyContent: 'center'
  },

  emptyStateContainer: {
    padding: theme.spacing(5, 10),
    width: '100%',
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(5, 0)
    }
  },

  submitButton: {
    marginTop: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      fontWeight: theme.typography.fontWeightBold
    }
  },

  sharedTripContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    width: '100%',
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(3)
  },
  tripDetailsContainer: {
    width: '100%',
    paddingBottom: theme.spacing(2)
  },

  sharedTripSubtitle: {
    textAlign: 'left',
    fontWeight: theme.typography.fontWeightRegular,
    color: theme.palette.grey[500],
    paddingBottom: theme.spacing(2)
  },
  hourrierCardContainer: {
    width: '100%',
    paddingBottom: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      paddingBottom: 0
    }
  },

  overviewContainer: {
    [theme.breakpoints.down('md')]: {
      position: 'fixed',
      bottom: 0,
      left: 0,
      right: 0,
      backgroundColor: theme.palette.background.default,
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(4),
      boxShadow: '0px -2px 4px rgba(0,0,0,0.32)'
    }
  },

  divider: {
    [theme.breakpoints.down('md')]: {
      backgroundColor: theme.palette.divider,
      marginY: '12px',
      marginX: theme.spacing(3)
    }
  }
}
