import theme from '../../../../shared/styles/themes/default.theme'

export const styles = {
  container: {
    textAlign: 'left',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    backgroundColor: theme.palette.background.default,
    borderRadius: theme.shape.borderRadius / 4,
    boxShadow: theme.shadows[3],
    padding: theme.spacing(4)
  },

  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 2
  },

  title: {
    fontWeight: 'bold'
  },

  buttonContainer: {
    display: 'flex',
    gap: 1
  },

  body: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between'
  }
}
