import theme from '../../../shared/styles/themes/default.theme'

const styles = {
  chip: (isCompact: boolean) => ({
    borderRadius: '5px',
    padding: 0,
    [theme.breakpoints.down('md')]: {
      height: isCompact ? '50px' : '40px'
    },

    '& .label-text': {
      marginRight: theme.spacing(1),
      [theme.breakpoints.down('md')]: {
        marginRight: 0
      }
    },

    '& .error-text': {
      fontWeight: 'bold',
      lineHeight: '14px',
      fontSize: '14px'
    },

    fontSize: '14px',

    backgroundColor: '#f9fafb',

    borderColor: '#D1D5DB',
    borderStyle: 'solid',
    borderWidth: '1.5px',

    justifyContent: 'center',
    lineHeight: '14px',

    '& .MuiChip-label': {
      // padding: '0 !important'
    }
  }),

  chipCloseIcon: {
    fill: 'black',

    width: '16px',
    height: '16px'
  },

  chipText: (isCompact: boolean) => ({
    [theme.breakpoints.down('md')]: {
      textWrap: 'wrap',
      textAlign: 'left',
      wordWrap: 'break-word',
      width: isCompact ? 'calc(100% + 30px)' : 'calc(100% - 20px)'
    }
  }),

  errorText: {
    marginLeft: theme.spacing(1),
    [theme.breakpoints.down('md')]: {
      textWrap: 'wrap',
      textAlign: 'left'
    }
  },

  errorChip: {},
  errorChipText: {}
}

styles.errorChip = {
  ...styles.chip(false),

  color: 'black',
  borderColor: 'red'
}

styles.errorChipText = {
  ...styles.chipText(false),

  width: '100% !important'
}

export default styles
