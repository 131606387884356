import theme from '../../styles/themes/default.theme'

const styles = {
  container: (isCompact: boolean) => ({
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    gap: theme.spacing(1),
    padding: isCompact ? theme.spacing(1, 1.75) : theme.spacing(2),
    borderRadius: 1,
    border: `1px solid ${theme.palette.grey[200]}`,
    backgroundColor: theme.palette.background.paper,
    minHeight: { md: isCompact ? '50px' : '70px', xs: isCompact ? '32px' : '120px' },
    width: '100%',

    '&:focus-within': {
      borderColor: theme.palette.primary.main
    },

    '&:hover:not(:focus-within)': {
      borderColor: theme.palette.grey[300]
    },

    [theme.breakpoints.down('md')]: {
      padding: isCompact ? theme.spacing(1, 1) : theme.spacing(2),
      maxWidth: isCompact ? `calc(100% - ${theme.spacing(14.25)})` : 'unset' // 14.25 is the width of the button next to the input in mobile + the right margin of the button
    }
  }),

  input: (isCompact: boolean) => ({
    flex: 1,
    '& .MuiInputBase-input': {
      padding: { md: isCompact ? theme.spacing(0.25, 0) : theme.spacing(0, 2), xs: 0 },
      [theme.breakpoints.down('md')]: {
        fontSize: '14px',
        '&::placeholder': {
          fontWeight: isCompact ? 'unset' : '600'
        }
      }
    }
  }),

  chip: (isCompact: boolean) => ({
    '& .error-text': {
      fontSize: '14px'
    },
    fontSize: '14px',
    '& .label-text': {
      [theme.breakpoints.down('md')]: {
        marginRight: isCompact ? 0 : 'unset',
        width: isCompact ? 'calc(100% - 25px)' : 'unset'
      }
    }
  })
}

export default styles
