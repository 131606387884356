import { SxProps } from '@mui/material'

import theme from '../../../../shared/styles/themes/default.theme'

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: theme.spacing(3),
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius / 4,
    backgroundColor: theme.palette.background.default,
    width: '100%',
    [theme.breakpoints.down('md')]: {
      borderRadius: 0,
      border: 'none',
      paddingY: 0
    }
  } as SxProps,

  title: {
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.grey[800],
    fontSize: theme.typography.body1.fontSize,
    marginBottom: theme.spacing(1)
  } as SxProps,

  divider: {
    width: '100%',
    backgroundColor: theme.palette.divider,
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(1),
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  } as SxProps,

  content: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '100%'
  } as SxProps,

  details: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    marginTop: theme.spacing(-0.75)
  } as SxProps,

  name: {
    fontWeight: theme.typography.fontWeightMedium,
    color: theme.palette.grey[800],
    textAlign: 'left'
  } as SxProps,

  travelInfo: {
    display: 'flex',
    alignItems: 'center',
    textAlign: 'left'
  } as SxProps,

  locationIcon: {
    color: theme.palette.grey[400],
    fontSize: '1.2rem',
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(-0.5)
  } as SxProps,

  travelText: {
    color: theme.palette.grey[600]
  }
}

export default styles
