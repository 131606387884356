import { CSSProperties } from 'react'

import { SxProps } from '@mui/material'

import theme from '../../../../shared/styles/themes/default.theme'

const styles = {
  offerContainer: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius / 4,
    padding: theme.spacing(3),
    boxShadow: theme.shadows[2]
  } as SxProps,

  topInfoContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: theme.palette.grey[100],
    borderRadius: theme.shape.borderRadius / 4,
    padding: theme.spacing(1.5, 2),
    marginBottom: theme.spacing(2)
  } as SxProps,

  avatar: {
    marginRight: theme.spacing(2)
  } as SxProps,

  userContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start'
  } as SxProps,

  userName: {
    fontWeight: theme.typography.fontWeightMedium,
    color: theme.palette.grey[800]
  } as SxProps,

  userLocation: {
    color: theme.palette.grey[500]
  } as SxProps,

  getAmount: {
    marginLeft: 'auto',
    color: theme.palette.secondary.dark
  } as SxProps,

  itemContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2),
    borderBottom: `1px solid ${theme.palette.divider}`
  },

  itemImageContainer: {
    width: '50px',
    height: '50px',
    marginRight: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden'
  },

  itemImage: {
    width: '100%',
    height: '100%',
    objectFit: 'contain',
    alignSelf: 'center'
  } as CSSProperties,
  itemDetails: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    textAlign: 'left',
    flex: 1,
    marginLeft: theme.spacing(1)
  },

  itemName: {
    fontSize: theme.typography.subtitle1.fontSize,
    fontWeight: theme.typography.fontWeightBold,
    textAlign: 'left',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: '3',
    WebkitBoxOrient: 'vertical'
  } as SxProps,

  itemInfo: {
    color: theme.palette.text.secondary,
    fontSize: '0.875rem'
  },

  itemPrice: {
    color: theme.palette.text.secondary,
    fontSize: '0.875rem'
  },

  viewOnAmazonButton: {
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightMedium,
    whiteSpace: 'nowrap',
    textTransform: 'none',
    fontSize: '1rem',
    marginLeft: theme.spacing(1),
    padding: theme.spacing(1, 4)
  } as SxProps,

  bottomInfoContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },

  locationInfoContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    backgroundColor: theme.palette.grey[50],
    padding: theme.spacing(1.6, 2),
    borderRadius: theme.shape.borderRadius / 6,
    paddingRight: theme.spacing(2),
    textAlign: 'left',
    flex: 1
  } as SxProps,

  locationIcon: {
    display: 'flex',
    color: theme.palette.grey[400],
    fontSize: '1.2rem',
    marginRight: theme.spacing(1)
  } as SxProps,

  neededByContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    backgroundColor: theme.palette.grey[50],
    padding: theme.spacing(1.6, 2),
    borderRadius: theme.shape.borderRadius / 6,
    paddingRight: theme.spacing(2),
    flex: 1,
    alignSelf: 'stretch'
  },

  neededByIcon: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(2)
  } as SxProps,

  boldText: {
    fontWeight: theme.typography.fontWeightMedium,
    color: theme.palette.grey[600]
  } as CSSProperties,

  linkText: {
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightBold,
    textDecoration: 'none'
  } as CSSProperties,

  lightText: {
    fontWeight: theme.typography.fontWeightRegular,
    color: theme.palette.grey[500]
  } as CSSProperties,

  acceptButton: {
    marginTop: theme.spacing(2)
  } as SxProps
}

export default styles
