import { SxProps } from '@mui/material'

import theme from '../../../../styles/themes/default.theme'

const styles = {
  buttonContainer: {
    display: 'flex',
    gap: theme.spacing(0),
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column'
    }
  } as SxProps,

  signUpButtonContainerMobile: {
    width: '100%',
    marginBottom: theme.spacing(4)
  },

  loginButton: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    fontSize: theme.typography.pxToRem(16),
    width: '100%',
    [theme.breakpoints.down('md')]: {
      marginBottom: theme.spacing(2)
    }
  },

  loginButtonMobile: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    fontSize: theme.typography.pxToRem(16),
    marginBottom: theme.spacing(2),
    width: '100%'
  },

  signUpButtonMobile: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '100%'
  },

  buttonWrapper: {
    width: '100%'
  }
}

export default styles
