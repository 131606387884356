import React from 'react'

import { Box, Modal, Typography } from '@mui/material'

import StripeConnectOnboardingForm from '../StripeConnectOnboardingForm/StripeConnectOnboardingForm.component'

import styles from './StripeConnectOnboardingModal.styles'
import { StripeConnectOnboardingModalProps } from './StripeConnectOnboardingModal.types'

const StripeConnectOnboardingModal: React.FC<StripeConnectOnboardingModalProps> = ({
  open,
  onClose,
  accountSessionClientSecret,
  onOnboardingComplete
}) => {
  const handleOnboardingComplete = () => {
    onOnboardingComplete()
    onClose()
  }

  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={styles.modalContainer}>
        <Typography variant="h5" sx={styles.title}>
          Connect a bank account to receive payments
        </Typography>

        <Box sx={styles.formContainer}>
          <StripeConnectOnboardingForm
            accountSessionClientSecret={accountSessionClientSecret}
            onOnboardingComplete={handleOnboardingComplete}
          />
        </Box>
      </Box>
    </Modal>
  )
}

export default StripeConnectOnboardingModal
