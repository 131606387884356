import { SxProps } from '@mui/material'

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column'
  } as SxProps,

  offerItemContainer: {
    width: '100%'
  } as SxProps
}

export default styles
