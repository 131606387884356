import React, { useCallback, useEffect, useState } from 'react'

import { Box, Stack, Typography } from '@mui/material'
import _ from 'lodash'

import Button from '../../../../shared/components/Button/Button.component'
import LoyaltyProgramForm from '../../../../shared/components/LoyaltyProgramForm/LoyaltyProgramForm.component'

import { deleteLoyaltyProgram } from '../../../../redux/states/profile/deleteLoyaltyProgram/deleteLoyaltyProgram.slice'
import { DeleteLoyaltyProgramRequestPayload } from '../../../../redux/states/profile/deleteLoyaltyProgram/deleteLoyaltyProgram.types'
import { getLoyaltyPrograms } from '../../../../redux/states/profile/getLoyaltyPrograms/getLoyaltyPrograms.slice'
import { GetLoyaltyProgramsData } from '../../../../redux/states/profile/getLoyaltyPrograms/getLoyaltyPrograms.types'
import { updateLoyaltyProgram } from '../../../../redux/states/profile/updateLoyaltyProgram/updateLoyaltyProgram.slice'
import { UpdateLoyaltyProgramRequestPayload } from '../../../../redux/states/profile/updateLoyaltyProgram/updateLoyaltyProgram.types'
import Modal from '../../../../shared/components/Modal/Modal.functions'
import { useAppDispatch } from '../../../../shared/hooks/redux.hooks'
import { styles } from './LoyaltyProgramsList.styles'
import { LoyaltyProgramsListProps } from './LoyaltyProgramsList.types'

const LoyaltyProgramsList: React.FC<LoyaltyProgramsListProps> = ({ programs }) => {
  const [airlineIataCode, setAirlineIataCode] = useState('')
  const [loyaltyProgramAccountNumber, setLoyaltyProgramAccountNumber] = useState('')
  const [loyaltyProgramFormErrors, setLoyaltyProgramFormErrors] = useState({})
  const [initialAirlineCode, setInitialAirlineCode] = useState('')
  const [initialAccountNumber, setInitialAccountNumber] = useState('')
  const [updatingProgramId, setUpdatingProgramId] = useState<number | null>(null)
  const [deletingProgramId, setDeletingProgramId] = useState<number | null>(null)

  const dispatch = useAppDispatch()

  const handleUpdateLoyaltyProgram = useCallback(
    (program: GetLoyaltyProgramsData) => {
      if (_.isEmpty(loyaltyProgramFormErrors)) {
        setUpdatingProgramId(program.id)

        const updateLoyaltyProgramPayload: UpdateLoyaltyProgramRequestPayload = {
          request: {
            loyaltyProgramId: program.id,
            airlineIataCode: airlineIataCode,
            accountNumber: loyaltyProgramAccountNumber
          },

          onSuccess: () => {
            dispatch(getLoyaltyPrograms({})).then(() => {
              setUpdatingProgramId(null)
            })
          }
        }

        dispatch(updateLoyaltyProgram(updateLoyaltyProgramPayload))
      }
    },
    [dispatch, airlineIataCode, loyaltyProgramAccountNumber, loyaltyProgramFormErrors]
  )

  const reShowModalWithUpdatedValues = useCallback(
    (program: GetLoyaltyProgramsData) => {
      Modal.show({
        title: 'Loyalty Program',
        body: (
          <LoyaltyProgramForm
            setAirlineIataCode={setAirlineIataCode}
            placeholder
            setLoyaltyProgramAccountNumber={setLoyaltyProgramAccountNumber}
            setErrors={setLoyaltyProgramFormErrors}
            onSubmit={() => handleUpdateLoyaltyProgram(program)}
            initialAirlineIataCode={airlineIataCode || program.airlineIataCode}
            initialLoyaltyProgramAccountNumber={loyaltyProgramAccountNumber || program.accountNumber}
          />
        ),
        primaryButton: {
          label: 'Save',
          onClick: () => handleUpdateLoyaltyProgram(program),
          disabled:
            (airlineIataCode === initialAirlineCode && loyaltyProgramAccountNumber === initialAccountNumber) ||
            !_.isEmpty(loyaltyProgramFormErrors)
        },
        secondaryButton: {
          label: 'Cancel',
          onClick: () => {
            setAirlineIataCode('')
            setLoyaltyProgramAccountNumber('')
            setLoyaltyProgramFormErrors({})
            setInitialAirlineCode('')
            setInitialAccountNumber('')
          }
        }
      })
    },
    [
      airlineIataCode,
      loyaltyProgramAccountNumber,
      initialAirlineCode,
      initialAccountNumber,
      loyaltyProgramFormErrors,
      handleUpdateLoyaltyProgram
    ]
  )

  useEffect(() => {
    const currentProgram = programs.find((p) => p.accountNumber === initialAccountNumber)

    if (currentProgram && (airlineIataCode || loyaltyProgramAccountNumber)) {
      reShowModalWithUpdatedValues(currentProgram)
    }
  }, [airlineIataCode, initialAccountNumber, loyaltyProgramAccountNumber, programs, reShowModalWithUpdatedValues])

  const showEditLoyaltyProgramModal = (program: GetLoyaltyProgramsData) => {
    setAirlineIataCode(program.airlineIataCode)
    setLoyaltyProgramAccountNumber(program.accountNumber)
    setInitialAirlineCode(program.airlineIataCode)
    setInitialAccountNumber(program.accountNumber)
    reShowModalWithUpdatedValues(program)
  }

  const handleDeleteLoyaltyProgram = (program: GetLoyaltyProgramsData) => {
    setDeletingProgramId(program.id)

    const deleteLoyaltyProgramPayload: DeleteLoyaltyProgramRequestPayload = {
      request: {
        id: program.id
      },

      onSuccess: () => {
        dispatch(getLoyaltyPrograms({})).then(() => {
          setDeletingProgramId(null)
        })
      }
    }

    dispatch(deleteLoyaltyProgram(deleteLoyaltyProgramPayload))
  }

  const showDeleteLoyaltyProgramModal = (program: GetLoyaltyProgramsData) => {
    Modal.show({
      title: 'Delete Loyalty Program',
      body: 'Are you sure you want to delete this loyalty program from your profile?',
      primaryButton: {
        label: 'Yes, Delete',
        onClick: () => handleDeleteLoyaltyProgram(program)
      },
      secondaryButton: { label: 'Cancel' }
    })
  }

  const renderProgramItem = (program: GetLoyaltyProgramsData) => (
    <Box key={program.id} sx={styles.programContainer}>
      <Box>
        <Typography sx={styles.airlineName}>{program.airlineName}</Typography>

        <Typography sx={styles.accountNumber}>{program.accountNumber}</Typography>
      </Box>

      <Stack direction="row" spacing={1}>
        <Button
          buttonType="primary"
          outline
          text="Delete"
          onClick={() => showDeleteLoyaltyProgramModal(program)}
          loading={deletingProgramId === program.id}
        />

        <Button
          buttonType="primary"
          outline
          text="Edit"
          loading={updatingProgramId === program.id}
          onClick={() => showEditLoyaltyProgramModal(program)}
        />
      </Stack>
    </Box>
  )

  return <Box sx={styles.container}>{programs.map(renderProgramItem)}</Box>
}

export default LoyaltyProgramsList
