import { SxProps } from '@mui/material'

import theme from '../../styles/themes/default.theme'

const styles = {
  desktop: {
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  } as SxProps,

  mobile: {
    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  } as SxProps
}

export default styles
