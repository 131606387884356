import { CSSProperties } from 'react'

import { SxProps } from '@mui/material'

import theme from '../../styles/themes/default.theme'

const styles = {
  header: {
    paddingLeft: theme.spacing(10),
    paddingRight: theme.spacing(10),
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(5),
    backgroundColor: theme.palette.background.default,
    [theme.breakpoints.down('md')]: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(4)
    }
  } as SxProps,

  logoContainer: {
    mr: theme.spacing(2),
    display: { xs: 'none', md: 'flex' }
  } as SxProps,

  logo: {
    width: theme.spacing(4),
    height: theme.spacing(4),
    objectFit: 'contain'
  } as CSSProperties,

  logoCentre: {
    display: { xs: 'flex', md: 'none' },
    justifyContent: 'center',
    flexGrow: 1,
    marginLeft: theme.spacing(3)
  } as SxProps,

  navLinks: {
    flexGrow: 1,
    justifyContent: 'center',
    display: { xs: 'none', md: 'flex' }
  } as SxProps,

  button: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    fontSize: theme.typography.pxToRem(16)
  },

  rightSideButton: {
    fontSize: theme.typography.pxToRem(16)
  },

  profileButton: {
    p: 0,
    marginLeft: theme.spacing(1)
  } as SxProps,

  rightSideArea: {
    display: 'flex',
    gap: theme.spacing(0),
    alignItems: 'center'
  } as SxProps,

  leftSideArea: {
    display: 'flex',
    gap: theme.spacing(0),
    alignItems: 'center',
    marginRight: 'auto'
  } as SxProps
}

export default styles
