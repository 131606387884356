import { SxProps } from '@mui/material'

import theme from '../../../../shared/styles/themes/default.theme'

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    width: '100%'
  },

  title: {
    fontWeight: theme.typography.fontWeightRegular,
    color: theme.palette.grey[800],
    fontSize: theme.typography.body1.fontSize,
    marginBottom: theme.spacing(1)
  },

  tripDetails: {
    display: 'flex',
    flexDirection: { xs: 'column', md: 'row' },
    justifyContent: 'flex-start',
    width: '100%'
  },

  legContainer: {
    display: 'flex',
    flexDirection: { xs: 'column', md: 'row' },
    alignItems: { xs: 'flex-start', md: 'center' }
  },

  legDetails: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start'
  },

  route: {
    fontWeight: theme.typography.fontWeightMedium,
    textAlign: 'left'
  } as SxProps,

  arrival: {
    color: '#1a73e8'
  },

  separatorWrapper: {
    width: '100%'
  },

  separator: {
    width: { xs: '100%', md: '1px' },
    height: { xs: '1px', md: '40px' },
    backgroundColor: theme.palette.divider,
    marginLeft: { xs: 'unset', md: theme.spacing(3) },
    marginRight: { xs: 'unset', md: theme.spacing(3) },
    marginTop: { xs: theme.spacing(3), md: 'unset' },
    marginBottom: { xs: theme.spacing(3), md: 'unset' }
  },

  arrivalLabel: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    fontWeight: theme.typography.fontWeightMedium,
    color: theme.palette.grey[500],
    textAlign: 'left'
  } as SxProps,

  arrivalDate: {
    fontWeight: { md: theme.typography.fontWeightMedium, xs: theme.typography.fontWeightBold },
    color: theme.palette.secondary.dark,
    textAlign: 'left'
  } as SxProps
}

export default styles
