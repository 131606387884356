import { SxProps } from '@mui/material'

import theme from '../../../../shared/styles/themes/default.theme'

export default {
  container: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(3),
    boxShadow: theme.shadows[3],
    borderRadius: theme.shape.borderRadius / 4,
    backgroundColor: theme.palette.background.default,
    [theme.breakpoints.down('md')]: {
      borderRadius: 0,
      border: 'none',
      boxShadow: 'none',
      paddingY: 0,
      flexDirection: 'row',
      justifyContent: 'space-between'
    }
  },

  row: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(1),
    flexWrap: 'wrap',
    [theme.breakpoints.down('md')]: {
      marginBottom: 0,
      gap: theme.spacing(2)
    }
  },

  amountToPayContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexWrap: 'wrap'
  } as SxProps,

  divider: {
    margin: theme.spacing(2, 0)
  },

  title: {
    color: theme.palette.grey[800],
    fontWeight: theme.typography.fontWeightMedium,
    textAlign: 'left'
  } as SxProps,

  subtotalTitleText: {
    textAlign: 'left',
    paddingRight: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      fontWeight: theme.typography.fontWeightBold,
      fontSize: '14px'
    }
  } as SxProps,

  fieldText: {
    color: theme.palette.grey[500],
    fontWeight: 500,
    [theme.breakpoints.down('md')]: {
      display: 'flex'
    }
  },

  amountToPayText: {
    textAlign: 'left',
    fontWeight: theme.typography.fontWeightBold,
    paddingRight: theme.spacing(2)
  } as SxProps,

  subTotalRow: {
    display: 'flex',
    marginBottom: theme.spacing(1)
  } as SxProps,

  totalText: {
    [theme.breakpoints.down('md')]: {
      marginRight: theme.spacing(2.5)
    }
  },

  totalValueText: {
    [theme.breakpoints.down('md')]: {
      fontWeight: theme.typography.fontWeightBold,
      fontSize: '16px',
      color: theme.palette.blue[800],
      textAlign: 'left'
    }
  }
}
