import { SxProps } from '@mui/material'

import theme from '../../../../styles/themes/default.theme'

const styles = {
  profileMenu: {
    [theme.breakpoints.down('md')]: {
      '& .MuiMenu-paper': {
        width: '100%',
        maxWidth: '100%',
        left: 0,
        right: 0,
        boxShadow: 'none',
        mt: '1px'
      },
      '& .MuiPaper-root': {
        left: 'unset!important' // This removes the left positioning
      },
      '& .MuiList-root': {
        paddingX: theme.spacing(3),
        marginBottom: '-1px'
      }
    }
  } as SxProps,

  paper: {
    elevation: 0,
    sx: {
      filter: 'drop-shadow(0px 1px 0px rgba(0,0,0,0.32))',
      [theme.breakpoints.up('md')]: {
        overflow: 'visible',
        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
        mt: 1.5,
        width: 150,

        '& .MuiAvatar-root': {
          width: 32,
          height: 32,
          ml: -0.5,
          mr: 1
        },

        '&::before': {
          content: '""',
          display: 'block',
          position: 'absolute',
          top: 0,
          right: 80,
          width: 10,
          height: 10,
          bgcolor: 'background.paper',
          transform: 'translateY(-50%) rotate(45deg)',
          zIndex: 0
        }
      }
    }
  }
}

export default styles
