import React, { useCallback, useMemo } from 'react'

import { ExpandMore } from '@mui/icons-material'
import { Box, Divider, Grid, Typography } from '@mui/material'

import Accordion from '../../../../shared/components/Accordion/Accordion.component'
import Link from '../../../../shared/components/Link/Link.component'
import TrackingDetails from '../../../../shared/components/TrackingDetails/TrackingDetails.component'

import { acceptItemPriceChange } from '../../../../redux/states/items/acceptItemPriceChange/acceptItemPriceChange.slice'
import { AcceptItemPriceChangeRequestPayload } from '../../../../redux/states/items/acceptItemPriceChange/acceptItemPriceChange.types'
import { cancelItemRequest } from '../../../../redux/states/offers/cancelItemRequest/cancelItemRequest.slice'
import { CancelItemRequestRequestPayload } from '../../../../redux/states/offers/cancelItemRequest/cancelItemRequest.types'
import {
  clearItemRequestTrackingDetailsSuccess,
  getItemRequestTrackingDetails
} from '../../../../redux/states/offers/getItemRequestTrackingDetails/getItemRequestTrackingDetails.slice'
import { GetItemRequestTrackingDetailsRequestPayload } from '../../../../redux/states/offers/getItemRequestTrackingDetails/getItemRequestTrackingDetails.types'
import { reassignItemRequest } from '../../../../redux/states/offers/reassignItemRequest/reassignItemRequest.slice'
import { ReassignItemRequestRequestPayload } from '../../../../redux/states/offers/reassignItemRequest/reassignItemRequest.types'
import { RootState } from '../../../../redux/store/store.types'
import { AccordionAlertProps, AccordionButtonProps } from '../../../../shared/components/Accordion/Accordion.types'
import Modal from '../../../../shared/components/Modal/Modal.functions'
import status from '../../../../shared/dictionaries/status.dictionaries'
import date, { dateTimeFormats } from '../../../../shared/functions/Date/date.functions'
import { UserRole } from '../../../../shared/functions/UserRole/userRoleManagement.types'
import { getUserRole } from '../../../../shared/functions/UserRole/userRoleManagment.functions'
import { useAppDispatch, useAppSelector } from '../../../../shared/hooks/redux.hooks'
import styles from './ItemRequestAccordion.styles'
import { ItemRequestAccordionProps } from './ItemRequestAccordion.types'

const ItemRequestAccordion: React.FC<ItemRequestAccordionProps> = (props) => {
  const { itemRequest, deliveryDetails, tripDetails, refreshOfferRequests, isInteractionEnabled } = props

  const [expandedItemId, setExpandedItemId] = React.useState<string | null>(null)
  const [loadingAcceptItemIds, setLoadingAcceptItemIds] = React.useState<string[]>([])
  const [loadingCancelItemIds, setLoadingCancelItemIds] = React.useState<string[]>([])
  const [loadingReassignItemIds, setLoadingReassignItemIds] = React.useState<string[]>([])

  const { loading: cancelItemRequestLoading } = useAppSelector((state: RootState) => state.cancelItemRequest)
  const { loading: acceptItemPriceChangeLoading } = useAppSelector((state: RootState) => state.acceptItemPriceChange)
  const { loading: reassignItemRequestLoading } = useAppSelector((state: RootState) => state.reassignItemRequest)
  const { loading: getOfferRequestsLoading } = useAppSelector((state: RootState) => state.getOfferRequests)

  const dispatch = useAppDispatch()

  const currentAlert = useMemo(() => {
    if (itemRequest.itemDetails.priceChangeDetails.length > 0) {
      const latestPriceChangeDetail = itemRequest.itemDetails.priceChangeDetails[0]
      const latestAvailabilityChangeDetail = itemRequest.itemDetails.availabilityChangeDetails[0]

      if (latestPriceChangeDetail) {
        const itemPriceIncreased =
          latestPriceChangeDetail.changed &&
          latestPriceChangeDetail.newValue > latestPriceChangeDetail.oldValue &&
          latestPriceChangeDetail.newValue !== itemRequest.acceptedItemPrice

        if (itemPriceIncreased && latestPriceChangeDetail.acceptedByShopper === false) {
          return {
            message: `Price changed on Amazon from $${latestPriceChangeDetail.oldValue.toFixed(2)} to $${latestPriceChangeDetail.newValue.toFixed(2)}.`,
            type: 'warning',
            tag: 'price-increased'
          } as AccordionAlertProps
        }
      } else if (latestAvailabilityChangeDetail) {
        const itemOutOfStock =
          latestAvailabilityChangeDetail.changed &&
          latestAvailabilityChangeDetail.newValue === 'Out of Stock' &&
          latestAvailabilityChangeDetail.oldValue === 'In Stock'

        if (itemOutOfStock) {
          return {
            message: 'The item is currently out of stock on Amazon.',
            type: 'warning',
            tag: 'out-of-stock'
          } as AccordionAlertProps
        }
      }
    }

    return null
  }, [
    itemRequest.itemDetails.priceChangeDetails,
    itemRequest.itemDetails.availabilityChangeDetails,
    itemRequest.acceptedItemPrice
  ])

  const handleTrackButtonPress = useCallback(
    (itemRequestId: number) => {
      const itemRequestIdString = itemRequestId.toString()

      if (expandedItemId === itemRequestIdString) {
        setExpandedItemId(null)
        dispatch(clearItemRequestTrackingDetailsSuccess())
      } else {
        const payload: GetItemRequestTrackingDetailsRequestPayload = {
          request: {
            itemRequestId: itemRequestIdString
          }
        }

        setExpandedItemId(itemRequestIdString)
        dispatch(getItemRequestTrackingDetails(payload))
      }
    },
    [dispatch, expandedItemId]
  )

  const handleAccordionExpansion = useCallback(
    (isExpanded: boolean, accordionKey: string | number | undefined) => {
      if (isExpanded) {
        setExpandedItemId(accordionKey?.toString() ?? null)
        handleTrackButtonPress(itemRequest.itemRequestId)
      } else {
        setExpandedItemId(null)
      }
    },
    [itemRequest.itemRequestId, handleTrackButtonPress]
  )

  const handleCancel = useCallback(() => {
    const payload: CancelItemRequestRequestPayload = {
      request: {
        itemRequestId: itemRequest.itemRequestId.toString()
      },
      onSuccess: () => {
        refreshOfferRequests(() =>
          setLoadingCancelItemIds((prev) => prev.filter((id) => id !== itemRequest.itemRequestId.toString()))
        )
      }
    }

    Modal.show({
      title: 'Cancel Item Request',
      body: 'Are you sure you want to cancel this item request? This will remove the item from your order.',
      primaryButton: {
        label: 'Yes, Cancel',
        onClick: () => {
          setLoadingCancelItemIds((prev) => [...prev, itemRequest.itemRequestId.toString()])
          dispatch(cancelItemRequest(payload))
        }
      },
      secondaryButton: {
        label: 'Dismiss'
      }
    })
  }, [dispatch, itemRequest.itemRequestId, refreshOfferRequests])

  const handleAcceptPriceChange = useCallback(() => {
    const payload: AcceptItemPriceChangeRequestPayload = {
      request: {
        itemRequestId: itemRequest.itemRequestId.toString()
      },
      onSuccess: () => {
        refreshOfferRequests(() =>
          setLoadingAcceptItemIds((prev) => prev.filter((id) => id !== itemRequest.itemRequestId.toString()))
        )
      }
    }

    setLoadingAcceptItemIds((prev) => [...prev, itemRequest.itemRequestId.toString()])
    dispatch(acceptItemPriceChange(payload))
  }, [dispatch, itemRequest.itemRequestId, refreshOfferRequests])

  const handleFindTraveler = useCallback(() => {
    const payload: ReassignItemRequestRequestPayload = {
      request: {
        itemRequestId: itemRequest.itemRequestId.toString()
      },
      onSuccess: () => {
        refreshOfferRequests(() =>
          setLoadingReassignItemIds((prev) => prev.filter((id) => id !== itemRequest.itemRequestId.toString()))
        )
      }
    }

    setLoadingReassignItemIds((prev) => [...prev, itemRequest.itemRequestId.toString()])
    dispatch(reassignItemRequest(payload))
  }, [dispatch, itemRequest.itemRequestId, refreshOfferRequests])

  const renderItemDetails = useCallback(() => {
    const itemRequestAccordionHeaderButtons: AccordionButtonProps[] = []
    const priceIncreaseDetected = currentAlert?.tag === 'price-increased'

    const isTrackButtonVisible = () => {
      const deliveryStatus = itemRequest?.itemDetails.status?.name
      const isFlightBooked = tripDetails?.tripStatus === status.flightStatus.flightBooked
      let isVisible = false
      let isValidDeliveryStatus = false

      switch (deliveryStatus) {
        case status.itemRequestStatus.acceptedByTraveler:
        case status.deliveryStatus.purchasedByTraveler:
        case status.deliveryStatus.inTransitToTraveler:
        case status.deliveryStatus.deliveredToTravelerAddress:
        case status.deliveryStatus.confirmedReceivedByTraveler:
        case status.deliveryStatus.confirmedInDestinationCountry:
        case status.deliveryStatus.confirmedDeliveredToShopperByTraveler:
          isValidDeliveryStatus = true
          break
      }

      if (isFlightBooked && isValidDeliveryStatus) {
        isVisible = true
      }

      return isVisible
    }

    const cancelItemRequestButton: AccordionButtonProps = {
      text: 'Cancel',
      buttonType: 'tertiary',
      onClick: handleCancel,
      disabled: loadingAcceptItemIds.includes(itemRequest.itemRequestId.toString()),
      loading:
        (cancelItemRequestLoading || getOfferRequestsLoading) &&
        loadingCancelItemIds.includes(itemRequest.itemRequestId.toString())
    }

    switch (itemRequest?.itemDetails.status?.name) {
      case status.itemRequestStatus.deniedByTraveler:
        itemRequestAccordionHeaderButtons.push(cancelItemRequestButton)

        itemRequestAccordionHeaderButtons.push({
          text: 'Find Traveler',
          buttonType: 'primary',
          onClick: handleFindTraveler,
          loading:
            (reassignItemRequestLoading || getOfferRequestsLoading) &&
            loadingReassignItemIds.includes(itemRequest.itemRequestId.toString())
        })
        break

      case status.itemRequestStatus.cancelledByShopper:
        itemRequestAccordionHeaderButtons.push({
          text: 'Canceled',
          buttonType: 'tertiary',
          disabled: true
        })
        break

      case status.deliveryStatus.confirmedDeliveredToShopperByShopper:
        itemRequestAccordionHeaderButtons.push({
          text: getUserRole() === UserRole.shopper ? 'Received' : 'Delivered',
          buttonType: 'primary',
          disabled: true
        })

        break

      default:
        if (!isTrackButtonVisible()) {
          itemRequestAccordionHeaderButtons.push(cancelItemRequestButton)
        }
        break
    }

    if (isTrackButtonVisible()) {
      itemRequestAccordionHeaderButtons.push({
        text: 'Track',
        icon: (
          <ExpandMore
            style={{
              transform: expandedItemId === itemRequest.itemRequestId.toString() ? 'rotate(180deg)' : 'rotate(0deg)',
              transition: 'transform 0.3s'
            }}
          />
        ),
        iconPosition: 'end',
        onClick: () => handleTrackButtonPress(itemRequest.itemRequestId),
        expandAccordion: true,
        buttonType: 'primary'
      })
    }

    if (priceIncreaseDetected) {
      itemRequestAccordionHeaderButtons.push({
        text: 'Accept Change',
        buttonType: 'primary',
        onClick: handleAcceptPriceChange,
        loading:
          (acceptItemPriceChangeLoading || getOfferRequestsLoading) &&
          loadingAcceptItemIds.includes(itemRequest.itemRequestId.toString())
      })
    }

    // Disable interaction if the item request is not interactable
    if (!isInteractionEnabled) {
      itemRequestAccordionHeaderButtons.length = 0
    }

    const itemRequestAccordionHeader = {
      mainContent: (
        <Grid item key={itemRequest.itemRequestId} sx={styles.itemRequestContainer}>
          <Grid item md={6} sx={styles.itemDetailsContainer}>
            <Box sx={styles.itemImageContainer}>
              <img
                src={itemRequest.itemDetails.images[0]}
                alt={itemRequest.itemDetails.title}
                style={styles.itemImage}
              />
            </Box>

            <Box sx={styles.itemDetailsInfoContainer}>
              <Typography sx={styles.itemTitle}>{itemRequest.itemDetails.title}</Typography>

              <Link sx={styles.itemDetailsLinkText} href={itemRequest.itemDetails.internal_url} target="_blank">
                View on Amazon
              </Link>
            </Box>
          </Grid>

          <Divider orientation="vertical" flexItem sx={styles.itemDetailsDivider} />

          <Grid item md={2} sx={styles.shopperInfoContainer}>
            <Typography sx={styles.rowTitleText}>Quantity</Typography>

            <Typography sx={styles.rowValueText}>
              {itemRequest.itemDetails.quantity} item{itemRequest.itemDetails.quantity > 1 ? 's' : ''}
            </Typography>
          </Grid>

          <Divider orientation="vertical" flexItem sx={styles.itemDetailsDivider} />

          <Grid item md={4}>
            <Typography sx={styles.rowTitleText}>Price</Typography>

            <Typography sx={styles.rowValueText}>
              {`$${parseFloat(itemRequest.itemDetails.price).toFixed(2)}`}
            </Typography>

            <Typography sx={styles.amazonPriceText}>
              Amazon price as of{' '}
              {date(itemRequest.itemDetails.priceAt).format(
                `${dateTimeFormats.date.medium} ${dateTimeFormats.time.short}`
              )}{' '}
              EST
            </Typography>
          </Grid>
        </Grid>
      ),
      buttons: itemRequestAccordionHeaderButtons
    }

    const getTrackingDetails = () => (
      <TrackingDetails
        itemRequestId={itemRequest.itemRequestId}
        isOpen={expandedItemId === itemRequest.itemRequestId.toString()}
        key={itemRequest.itemRequestId}
        refreshMainList={refreshOfferRequests}
        destinationCity={deliveryDetails.city.name}
      />
    )

    const isTrackingDetailsAccessible =
      itemRequest.itemDetails.status.name === status.itemRequestStatus.acceptedByTraveler &&
      tripDetails?.tripStatus === status.flightStatus.flightBooked

    return (
      <Accordion
        header={itemRequestAccordionHeader}
        body={getTrackingDetails()}
        handleExpansion={handleAccordionExpansion}
        noExpandIcon
        noExpandBackgroundColor
        preventExpandChange={!isTrackingDetailsAccessible}
        alert={currentAlert || undefined}
        accordionKey={itemRequest.itemRequestId}
      />
    )
  }, [
    itemRequest,
    loadingAcceptItemIds,
    loadingCancelItemIds,
    loadingReassignItemIds,
    expandedItemId,
    handleCancel,
    handleAcceptPriceChange,
    handleFindTraveler,
    handleTrackButtonPress,
    currentAlert,
    acceptItemPriceChangeLoading,
    cancelItemRequestLoading,
    deliveryDetails,
    getOfferRequestsLoading,
    handleAccordionExpansion,
    isInteractionEnabled,
    reassignItemRequestLoading,
    refreshOfferRequests,
    tripDetails
  ])

  return <Box>{renderItemDetails()}</Box>
}

export default ItemRequestAccordion
