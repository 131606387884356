import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import urls from '../../../../networkRequests/apiClient/endpoints'
import errorHandler from '../../../../shared/functions/ErrorHandler/errorHandler.functions'
import { ApiErrorResponse } from '../../../../shared/types/api.types'
import { getLoyaltyProgramsApi } from './getLoyaltyPrograms.api'
import getLoyaltyProgramsInitialState from './getLoyaltyPrograms.initialState'
import { GetLoyaltyProgramsErrorResponse, GetLoyaltyProgramsRequestPayload } from './getLoyaltyPrograms.types'

export const getLoyaltyPrograms = createAsyncThunk(
  urls.profile.getLoyaltyPrograms,
  async (payload: GetLoyaltyProgramsRequestPayload, { rejectWithValue }) => {
    const { onSuccess } = payload || {}

    try {
      const response = await getLoyaltyProgramsApi()

      onSuccess && onSuccess(response?.data)

      return response
    } catch (error) {
      errorHandler.handleApiError(error as ApiErrorResponse)

      return rejectWithValue(error)
    }
  }
)

const getLoyaltyProgramsSlice = createSlice({
  name: urls.profile.getLoyaltyPrograms,
  initialState: getLoyaltyProgramsInitialState,
  reducers: {
    resetGetLoyaltyPrograms: (state) => {
      state.loading = false
      state.error = null
      state.success = null
    }

    // Add any additional reducers here
  },
  extraReducers: (builder) => {
    builder
      .addCase(getLoyaltyPrograms.pending, (state) => {
        state.loading = true
        state.error = null
        state.success = null
      })
      .addCase(getLoyaltyPrograms.fulfilled, (state, { payload }) => {
        state.loading = false
        state.success = payload
        state.error = null
      })
      .addCase(getLoyaltyPrograms.rejected, (state, { payload }) => {
        state.loading = false
        state.error = payload as GetLoyaltyProgramsErrorResponse
        state.success = null
      })
  }
})

export const { actions: getLoyaltyProgramsActions, reducer: getLoyaltyProgramsReducer } = getLoyaltyProgramsSlice
export const { resetGetLoyaltyPrograms } = getLoyaltyProgramsActions
export default getLoyaltyProgramsSlice.reducer
