import { SxProps } from '@mui/material'

import theme from '../../shared/styles/themes/default.theme'

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    gap: theme.spacing(4),
    padding: theme.spacing(5, 10)
  },

  contentContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    gap: theme.spacing(4),
    width: '100%'
  },

  titleText: {
    color: theme.palette.grey[800],
    fontWeight: theme.typography.fontWeightBold,
    lineHeight: '32px',
    textAlign: 'left',
    marginBottom: theme.spacing(1)
  } as SxProps,

  profileHeader: {
    display: { xs: 'none', md: 'flex' },
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'column',
    padding: theme.spacing(4),
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6),
    backgroundColor: theme.palette.background.default,
    borderRadius: theme.shape.borderRadius / 4,
    boxShadow: theme.shadows[3]
  },

  profilePictureContainer: {
    marginBottom: theme.spacing(6)
  },

  profileButton: {
    marginBottom: theme.spacing(2)
  },

  profileFormsContainer: {
    gap: theme.spacing(4),
    width: '100%',
    display: 'flex',
    flexDirection: 'column'
  },

  changePasswordContainer: {
    textAlign: 'left',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    backgroundColor: theme.palette.background.default,
    borderRadius: theme.shape.borderRadius / 4,
    boxShadow: theme.shadows[3],
    padding: theme.spacing(4)
  },

  changePasswordText: {
    width: '70%',
    color: theme.palette.text.secondary,
    paddingRight: theme.spacing(2)
  },

  paymentMethodsContainer: {
    display: 'flex',
    gap: theme.spacing(2),
    flexDirection: 'column',
    width: '100%'
  },

  highlightText: {
    fontWeight: theme.typography.fontWeightMedium,
    color: theme.palette.secondary.main
  }
}

export default styles
