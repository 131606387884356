import { FlightItinerary } from '../../../../redux/states/flights/getAllFlightItineraries/getAllFlightItineraries.types'
import logger from '../../../../shared/functions/Logger/logger.functions'
import { truncateString } from '../../../../shared/functions/String/string.functions'
import { marketplaceCountryUrls } from './MarketplaceLinksInput.dictionaries'
import { MarketplaceUrlError } from './MarketplaceLinksInput.types'

const extractDomainAndTopLevelDomain = (url: string): { domain: string; topLevelDomain: string } | null => {
  try {
    const parsedUrl = new URL(url.startsWith('http') ? url : `https://${url}`)
    const parts = parsedUrl.hostname.split('.')

    // Handle cases like 'co.uk', 'co.jp', 'com.mx'
    let tld = parts.pop() || ''

    if (parts.length > 1 && (parts[parts.length - 1] === 'co' || parts[parts.length - 1] === 'com')) {
      tld = `${parts.pop()}.${tld}`
    }

    // For Amazon, we're interested in 'amazon' regardless of subdomains
    const domain = parts.includes('amazon') ? 'amazon' : parts.pop() || ''

    return { domain, topLevelDomain: tld }
  } catch (error) {
    logger.logError(error, 'Error extracting domain and top-level domain', 'extractDomainAndTopLevelDomain', {
      url
    })

    return null
  }
}

// TODO: Resolve bug with uk marketplace and others not being validated properly
export const isValidMarketplaceUrl = (
  url: string,
  setError?: (error: MarketplaceUrlError) => void,
  flightItinerary?: FlightItinerary
) => {
  const domainInfo = extractDomainAndTopLevelDomain(url)
  const isUrl = !!domainInfo
  let error: MarketplaceUrlError | null = null
  let isValidUrl = false

  if (isUrl) {
    const { domain, topLevelDomain } = domainInfo

    const amazonCountryUrl = marketplaceCountryUrls.amazon.find((entry) => entry.url === `${domain}.${topLevelDomain}`)

    if (amazonCountryUrl) {
      if (flightItinerary) {
        const destinationCountries = flightItinerary.itinerary.legs.map((leg) => leg.destination.country.iataCode)
        const originCountries = flightItinerary.itinerary.legs.map((leg) => leg.origin.country.iataCode)
        const travelerCountries = [...destinationCountries, ...originCountries]

        if (travelerCountries.includes(amazonCountryUrl.countryCode)) {
          isValidUrl = true
        } else {
          error = {
            error: {
              message: `Marketplace (${truncateString(`${domain}.${topLevelDomain}`, 30)}) is not valid for the destination.`,
              type: 'destination'
            },
            url
          }
        }
      } else {
        isValidUrl = true
      }
    } else {
      error = {
        error: {
          message: `"${truncateString(`${domain}.${topLevelDomain}`, 30)}" is not a valid Amazon marketplace URL.`,
          type: 'amazon'
        },
        url
      }
    }
  } else {
    error = {
      error: {
        message: `"${truncateString(url, 20)}" is not a valid URL.`,
        type: 'marketplace'
      },
      url
    }
  }

  if (error && setError) {
    setError(error)
  }

  return isValidUrl
}
