import { configureRequest } from '../../../../networkRequests/apiClient/apiClient.functions'
import urls from '../../../../networkRequests/apiClient/endpoints'
import {
  DeleteLoyaltyProgramErrorResponse,
  DeleteLoyaltyProgramRequest,
  DeleteLoyaltyProgramSuccessResponse
} from './deleteLoyaltyProgram.types'

export const deleteLoyaltyProgramApi = async (
  request: DeleteLoyaltyProgramRequest
): Promise<DeleteLoyaltyProgramSuccessResponse> => {
  const response = await configureRequest({
    url: urls.profile.deleteLoyaltyProgram,
    method: 'DELETE',
    data: request,
    params: {
      id: request.id
    }
  })

  if (response.status >= 200 && response.status < 300) {
    return response as DeleteLoyaltyProgramSuccessResponse
  } else {
    throw response as DeleteLoyaltyProgramErrorResponse
  }
}

export default deleteLoyaltyProgramApi
