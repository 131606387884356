import React, { useEffect } from 'react'

import { AppBar, Box, Toolbar } from '@mui/material'

import Button from '../Button/Button.component'
import ResponsiveDisplayWrapper from '../ResponsiveDisplayWrapper/ResponsiveDisplayWrapper.component'
import AuthButtons from './components/AuthButtons/AuthButtons.component'
import OverFlowMenu from './components/OverFlowMenu/OverFlowMenu.component'
import ProfileMenu from './components/ProfileMenu/ProfileMenu.component'

import images from '../../../assets/images'
import { isAuthenticated } from '../../../networkRequests/apiClient/apiClient.functions'
import router from '../../../router/functions/router.functions'
import routes from '../../../router/routes.dictionary'
import { UserRole } from '../../functions/UserRole/userRoleManagement.types'
import { getUserRole, setUserRole } from '../../functions/UserRole/userRoleManagment.functions'
import styles from './Header.styles'
import { HeaderProps } from './Header.types'

const pages = [routes.home, routes.shop, routes.cart, routes.offers, routes.orders, routes.myTrips, routes.requests]

const Header: React.FC<HeaderProps> = (props) => {
  const { sticky = true } = props

  const navigate = router.navigate()
  const currentPage = router.getCurrentPage()

  const [currentUserRole, setCurrentUserRole] = React.useState<UserRole>(
    (getUserRole() as UserRole) || UserRole.traveler
  )

  useEffect(() => {
    setUserRole(currentUserRole)
  }, [currentUserRole])

  const isSticky = React.useMemo(() => {
    switch (currentPage.path) {
      case routes.cart.path:
        return false

      default:
        return sticky
    }
  }, [currentPage.path, sticky])

  const handleUserRoleSwitch = (newUserRole: UserRole) => {
    setCurrentUserRole(newUserRole)

    switch (newUserRole) {
      case UserRole.traveler:
        navigate(routes.home.path)
        break

      case UserRole.shopper:
        navigate(routes.shop.path)
        break
    }
  }

  const getFilteredPages = () => {
    return pages.filter((page) => {
      if (!page?.userRole) {
        return true
      }

      if (page.isAuthenticationRequired && !isAuthenticated()) {
        return false
      }

      // Show Shop page when user is not authenticated
      if ((page.path === routes.shop.path || page.path === routes.home.path) && !isAuthenticated()) {
        return true
      }

      return page?.userRole.includes(currentUserRole)
    })
  }

  const getNavigationLinks = () => {
    const filteredPages = getFilteredPages()

    return (
      <Box sx={styles.navLinks}>
        {filteredPages.map((page, index) => {
          const isActive = currentPage.path === page?.path

          return (
            <Button
              buttonType="tertiary"
              text={page?.name}
              onClick={() => navigate(page?.path)}
              isNav
              isActiveNav={isActive}
              style={styles.button}
              key={index}
            />
          )
        })}
      </Box>
    )
  }

  const logo = (centre: boolean = false) => {
    return (
      <Box sx={centre ? styles.logoCentre : styles.logoContainer} onClick={() => navigate(routes.home.path)}>
        <img src={images.hourrierLogo} alt="Logo" style={styles.logo} />
      </Box>
    )
  }

  const getRightSide = () => {
    if (isAuthenticated()) {
      let newUserRole: UserRole
      let userRoleText = ''

      switch (currentUserRole) {
        case UserRole.traveler:
          userRoleText = 'Switch to Shopping'
          newUserRole = UserRole.shopper
          break

        case UserRole.shopper:
          userRoleText = 'Switch to Traveling'
          newUserRole = UserRole.traveler
          break
      }

      return (
        <ProfileMenu
          userRoleText={userRoleText}
          onClick={() => handleUserRoleSwitch(newUserRole)}
          buttonIconStyle={styles.rightSideButton}
          containerStyle={styles.rightSideArea}
          isAuthenticated={true}
        />
      )
    } else {
      return (
        <>
          <ResponsiveDisplayWrapper isDesktop={true}>
            <AuthButtons currentPage={currentPage} />
          </ResponsiveDisplayWrapper>

          <ResponsiveDisplayWrapper isDesktop={false}>
            <ProfileMenu
              buttonIconStyle={styles.rightSideButton}
              containerStyle={styles.rightSideArea}
              isAuthenticated={false}
            />
          </ResponsiveDisplayWrapper>
        </>
      )
    }
  }

  return (
    <AppBar
      color="transparent"
      sx={{
        ...styles.header,
        position: {
          xs: isSticky ? 'sticky' : 'static',
          md: 'static'
        }
      }}
    >
      <Box>
        <Toolbar disableGutters>
          {logo()}

          <Box sx={styles.leftSideArea}>
            <OverFlowMenu pages={getFilteredPages()} />
            {logo(true)}
          </Box>

          {getNavigationLinks()}

          {getRightSide()}
        </Toolbar>
      </Box>
    </AppBar>
  )
}

export default Header
