import React from 'react'

import { FormikProps } from 'formik'
import * as Yup from 'yup'

import Form from '../Form/Form.component'

import { RootState } from '../../../redux/store/store.types'
import { useAppSelector } from '../../hooks/redux.hooks'
import { FormField } from '../Form/Form.types'
import { LoyaltyProgramFormProps, LoyaltyProgramFormValues } from './LoyaltyProgramForm.types'

const validationSchema = Yup.object({
  airlineIataCode: Yup.string().required('Required').min(1, 'Required'),
  loyaltyProgramAccountNumber: Yup.string().required('Required').min(1, 'Required')
})

const LoyaltyProgramForm: React.FC<LoyaltyProgramFormProps> = (props) => {
  const { setAirlineIataCode, setLoyaltyProgramAccountNumber, setErrors, onSubmit } = props

  const supportedLoyaltyPrograms = useAppSelector(
    (state: RootState) => state.getSupportedLoyaltyPrograms.success?.data || []
  )

  const initialValues: LoyaltyProgramFormValues = {
    airlineIataCode: props.initialAirlineIataCode || '',
    loyaltyProgramAccountNumber: props.initialLoyaltyProgramAccountNumber || ''
  }

  const handleValuesChange = (formState: Partial<FormikProps<unknown>>) => {
    const typedFormState = formState as Partial<FormikProps<LoyaltyProgramFormValues>>

    if (typedFormState.values) {
      setAirlineIataCode(typedFormState.values.airlineIataCode ?? '')
      setLoyaltyProgramAccountNumber(typedFormState.values.loyaltyProgramAccountNumber ?? '')
      setErrors(typedFormState.errors ?? {})
    }
  }

  const formFields: FormField[] = [
    {
      name: 'airlineIataCode',
      label: 'Program Name',
      placeholder: props.placeholder ? 'American Airlines' : undefined,
      type: 'select',
      options: supportedLoyaltyPrograms.map((program) => ({
        value: program.airline_iata_code,
        label: `${program.airline_name} - ${program.name}`
      })),
      grid: { xs: 12 },
      required: true,
      fullWidth: true
    },
    {
      name: 'loyaltyProgramAccountNumber',
      label: 'Program Number',
      placeholder: props.placeholder ? '32092383' : undefined,
      type: 'text',
      grid: { xs: 12 },
      required: true,
      fullWidth: true
    }
  ]

  return (
    <Form
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      formFields={formFields}
      buttonText="Add Loyalty Program"
      isContained={false}
      onFormChange={handleValuesChange}
      hideButtons={true}
    />
  )
}

export default LoyaltyProgramForm
