import { Box, TextField, Typography, TypographyProps } from '@mui/material'

import Button from '../Button/Button.component'
import Link from '../Link/Link.component'

import images from '../../../assets/images'
import env from '../../../networkRequests/apiClient/env.config'
import routes from '../../../router/routes.dictionary'
import date from '../../functions/Date/date.functions'
import Modal from '../Modal/Modal.functions'
import styles from './Footer.styles'
import { FooterItem, FooterSection } from './Footer.types'

const openCustomerSupportModal = () => {
  Modal.show({
    title: 'Customer Support',
    body: `Having trouble? We are here to help!\n\nEmail us at ${env.SUPPORT_EMAIL_ADDRESS} and we will get back to you as soon as possible.`,
    primaryButton: {
      label: 'Send Message',
      onClick: () => {
        window.location.href = `mailto:${env.SUPPORT_EMAIL_ADDRESS}`
      }
    },
    secondaryButton: {
      label: 'Dismiss'
    }
  })
}

export function Copyright(props: TypographyProps) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {`© ${date().year} `}
      <Link style={styles.link} href={env.WEB_APP_URL}>
        {env.BUSINESS_NAME}
      </Link>{' '}
    </Typography>
  )
}

export const showFooterSection = (section: FooterSection, isMain: boolean, isDesktop: boolean) => {
  return (isMain || isDesktop || section.hasSecondaryFooterContent) && !section.isHidden
}

export const showFooterItem = (item: FooterItem, isMain: boolean, isDesktop: boolean) => {
  return (
    item.component &&
    (((isMain || isDesktop) && !item.hideMainFooter) || (!(isMain || isDesktop) && item.secondaryFooter))
  )
}

export const footerSections = (isDesktop: boolean) => [
  {
    isHidden: !isDesktop,
    title: '',
    items: [{ component: <img src={images.hourrierLogoWithText} alt="Hurrier Logo" style={styles.hourrierLogo} /> }]
  },
  {
    title: 'Make & Save Money',
    items: [
      // <Link href={routes.howHourrierWorks.path}>How Hurrier Works</Link>,
      // <Link href={routes.flightDeals.path}>Flight Deals</Link>,
      { component: <Link href={routes.shop.path}>Make an Offer</Link> }
      // <Link href={routes.hourrierExperience.path}>The Hurrier Experience</Link>,
      // <Link href={routes.aboutUs.path}>About Us</Link>
    ]
  },
  {
    title: 'Help & Support',
    hasSecondaryFooterContent: true,
    items: [
      // <Link href={routes.helpCenter.path}>Help Center</Link>,
      { secondaryFooter: true, component: <Link onClick={() => openCustomerSupportModal()}>Customer Support</Link> },
      { component: <Link onClick={() => openCustomerSupportModal()}>Contact Us</Link> }
    ]
  },
  {
    title: 'Good to Know',
    isHidden: true,
    hasSecondaryFooterContent: true,
    items: [
      { secondaryFooter: true, component: <Link href={routes.termsOfServices.path}>Terms of Services</Link> },
      { component: <Link href={routes.trustAndSafety.path}>Trust & Safety</Link> },
      { component: <Link href={routes.privacyPolicy.path}>Privacy Policy</Link> },
      { component: <Link href={routes.deliveryPolicy.path}>Delivery Policy</Link> },
      { component: <Link href={routes.cookiePolicy.path}>Cookie Policy</Link> }
    ]
  },
  {
    title: 'Vibe With Us!',
    isHidden: true,
    items: [
      {
        component: (
          <Link href="https://www.instagram.com">
            <img src={images.instagramLogo} alt="Instagram" style={styles.icon} />
            Instagram
          </Link>
        )
      },

      {
        component: (
          <Link href="https://www.tiktok.com">
            <img src={images.tiktokLogo} alt="Tiktok" style={styles.icon} />
            Tiktok
          </Link>
        )
      },
      {
        component: (
          <Link href="https://www.x.com">
            <img src={images.xLogo} alt="X" style={styles.icon} />
            X.com
          </Link>
        )
      },
      {
        component: (
          <Link href="https://www.facebook.com">
            <img src={images.facebookLogo} alt="Facebook" style={styles.icon} />
            Facebook
          </Link>
        )
      }
    ]
  },
  {
    title: 'Join Community',
    isHidden: true,
    hasSecondaryFooterContent: true,
    items: [
      { component: <Typography>Subscribe to Hurrier and get updates on flight deals and more.</Typography> },
      {
        component: (
          <Box sx={styles.form}>
            <TextField label="Your email" variant="standard" size="small" sx={styles.textField} />
            <Button buttonType="primary" text="Subscribe" fullWidth />
          </Box>
        )
      },
      {
        hideMainFooter: true,
        secondaryFooter: true,
        component: <Link href={routes.cookiePolicy.path}>Join Community</Link>
      }
    ]
  }
]
