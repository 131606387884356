import React from 'react'

import { Box, Typography } from '@mui/material'

import Button from '../Button/Button.component'

import { styles } from './MainPageSection.styles'
import { MainPageSectionProps } from './MainPageSection.types'

const MainPageSection: React.FC<MainPageSectionProps> = (props) => {
  const { title, subtitle, children, headerAlignment = 'left', button } = props

  return (
    <Box sx={styles.container}>
      <Box sx={styles.header}>
        <Box sx={styles.headerContentLeft}>
          {title && (
            <Typography variant="h2" sx={styles.title(headerAlignment)}>
              {title
                .split(' ')
                .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                .join(' ')}
            </Typography>
          )}

          {subtitle && (
            <Typography variant="h5" sx={styles.subtitle(headerAlignment)}>
              {subtitle}
            </Typography>
          )}
        </Box>

        {button && (
          <Box sx={styles.headerContentRight}>
            <Button onClick={button.onClick} text={button.text} buttonType={'tertiary'} large={button.large} />
          </Box>
        )}
      </Box>

      <Box sx={styles.childrenContainer}>{children}</Box>
    </Box>
  )
}

export default MainPageSection
