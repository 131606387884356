import React, { useEffect } from 'react'

import { Autocomplete, Box, Grid, Switch, TextField, Typography } from '@mui/material'
import { FormikProps, useFormik } from 'formik'
import get from 'lodash/get'
import { MuiTelInput } from 'mui-tel-input'

import Button from '../../components/Button/Button.component'
import Loader from '../Loader/Loader.component'

import styles from './Form.styles'
import { FormField, FormFieldOption, FormProps, FormSection } from './Form.types'

const Form: React.FC<FormProps> = (props: FormProps) => {
  const {
    title,
    subtitle,
    initialValues,
    validationSchema,
    buttonText,
    isEditable = true,
    setIsEditable,
    isContained = false,
    hideButtons = false,
    onSubmit,
    onFormChange,
    formFields,
    formSections,
    customElement,
    loading,
    enableReinitialize
  } = props

  const formik: FormikProps<typeof initialValues> = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
    enableReinitialize
  })

  const getFieldValue = (fieldName: string) => {
    return get(formik.values, fieldName)
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const setFieldValue = (fieldName: string, value: any) => {
    formik.setFieldValue(fieldName, value)
  }

  useEffect(() => {
    if (onFormChange) {
      onFormChange({
        values: formik.values,
        touched: formik.touched,
        errors: formik.errors,
        isValid: formik.isValid
      })
    }
  }, [formik.values, formik.touched, formik.errors, formik.isValid, onFormChange])

  const renderFormFields = (fields?: FormProps['formFields']) => {
    if (!fields) return null

    return fields.map((field, index) => (
      <Grid
        item
        xs={field.grid?.xs}
        sm={field.grid?.sm}
        md={field.grid?.md}
        lg={field.grid?.lg}
        xl={field.grid?.xl}
        key={field.name}
      >
        {renderField(field, index)}
      </Grid>
    ))
  }

  const renderField = (field: FormField, index: number) => {
    const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
      if (field.onBlur) {
        field.onBlur()
      }

      formik.handleBlur(event)
    }

    const isDisabled = field.readonly ?? !isEditable

    switch (field.type) {
      case 'switch':
        return (
          <Box sx={styles.checkboxContainer}>
            <Typography sx={styles.checkboxText}>{field.label}</Typography>

            <Switch
              name={field.name}
              color="primary"
              checked={formik.values[field.name as keyof typeof initialValues] as boolean}
              onChange={formik.handleChange}
              role="checkbox"
            />
          </Box>
        )

      case 'select':
        return (
          <Autocomplete
            options={field.options || []}
            getOptionLabel={(option: FormFieldOption) => option.label}
            renderInput={(params: object) => (
              <TextField
                {...params}
                name={field.name}
                label={field.label}
                required={field.required}
                fullWidth
                disabled={isDisabled}
                sx={styles.textField(isEditable)}
                onBlur={formik.handleBlur}
              />
            )}
            readOnly={field.readonly}
            value={field.options?.find((option: FormFieldOption) => option.value === getFieldValue(field.name)) || null}
            onChange={(_event: unknown, newValue: FormFieldOption | null) => {
              setFieldValue(field.name, newValue ? newValue.value : '')

              if (field.onChange) {
                field.onChange({
                  target: { value: newValue ? newValue.value : '' }
                } as React.ChangeEvent<HTMLInputElement>)
              }
            }}
            disabled={isDisabled}
          />
        )

      case 'textarea':
        return (
          <TextField
            autoFocus={field?.autoFocus ?? (index === 0 && !field.preventAutoFocus)}
            name={field.name}
            required={field.required}
            fullWidth={field.fullWidth}
            id={field.name}
            disabled={!isEditable}
            label={field.label}
            value={getFieldValue(field.name)}
            placeholder={field.placeholder}
            onChange={(event) => setFieldValue(field.name, event.target.value)}
            onBlur={formik.handleBlur}
            error={get(formik.touched, field.name) && Boolean(get(formik.errors, field.name))}
            helperText={get(formik.touched, field.name) && get(formik.errors, field.name)}
            sx={styles.textField(isEditable)}
            multiline
            rows={field.rows || 4}
          />
        )

      case 'tel':
        return (
          <MuiTelInput
            name={field.name}
            label={field.label}
            value={getFieldValue(field.name) as string | undefined}
            onChange={(newValue: string) => {
              setFieldValue(field.name, newValue)

              if (field.onChange) {
                field.onChange({
                  target: { value: newValue }
                } as React.ChangeEvent<HTMLInputElement>)
              }
            }}
            onBlur={formik.handleBlur}
            error={get(formik.touched, field.name) && Boolean(get(formik.errors, field.name))}
            helperText={get(formik.touched, field.name) && get(formik.errors, field.name)}
            disabled={!isEditable}
            fullWidth={field.fullWidth}
            sx={styles.textField(isEditable)}
          />
        )

      default:
        return (
          <TextField
            autoFocus={field?.autoFocus ?? (index === 0 && !field.preventAutoFocus)}
            autoComplete={field.autoComplete}
            name={field.name}
            required={field.required}
            fullWidth={field.fullWidth}
            id={field.name}
            disabled={isDisabled}
            label={field.label}
            type={field.type}
            value={getFieldValue(field.name)}
            placeholder={field.placeholder}
            onChange={(event) => setFieldValue(field.name, event.target.value)}
            onBlur={handleBlur}
            error={get(formik.touched, field.name) && Boolean(get(formik.errors, field.name))}
            helperText={field.helperText || (get(formik.touched, field.name) && get(formik.errors, field.name))}
            sx={styles.textField(isEditable, isDisabled)}
            InputLabelProps={field.type === 'date' ? { shrink: true } : undefined}
            InputProps={{
              readOnly: field.readonly || false,
              endAdornment: field.endAdornment
            }}
          />
        )
    }
  }

  const renderFormContent = () => {
    if (formSections) {
      return formSections.map((section: FormSection, sectionIndex: number) => (
        <React.Fragment key={sectionIndex}>
          {section.title && (
            <Typography variant="h6" sx={styles.sectionTitle(section.alignment)}>
              {section.title}
            </Typography>
          )}

          <Grid container spacing={2}>
            {renderFormFields(section.fields)}
          </Grid>
        </React.Fragment>
      ))
    } else if (formFields) {
      return (
        <Grid container spacing={2}>
          {renderFormFields(formFields)}
        </Grid>
      )
    }
  }

  const getFormButtons = () => {
    let buttons = null

    if (hideButtons) {
      return <></>
    }

    if (isContained) {
      if (isEditable) {
        buttons = (
          <Box sx={styles.buttonGroup}>
            <Button
              buttonType="secondary"
              text="Cancel"
              isNav
              isActiveNav
              onClick={() => {
                setIsEditable && setIsEditable(false)
                formik.resetForm()
              }}
            />

            <Button
              text={buttonText}
              onClick={formik.handleSubmit as () => void}
              buttonType="primary"
              disabled={loading || !formik.isValid || !formik.dirty}
              loading={loading}
            />
          </Box>
        )
      }
    } else {
      buttons = (
        <Button
          text={buttonText}
          onClick={formik.handleSubmit as () => void}
          buttonType="primary"
          disabled={loading || !formik.isValid || !formik.dirty}
          loading={loading}
          fullWidth
          large
          marginTop="15px"
        />
      )
    }

    return buttons
  }

  const getFormHeaderText = () => {
    const titleStyle = isContained ? styles.containedTitle : styles.title

    if (isContained) {
      return (
        <Box sx={styles.containedTitleContainer}>
          {title != null && (
            <Typography variant="h5" style={titleStyle}>
              {title}
            </Typography>
          )}

          {loading && (
            <Box sx={styles.loading}>
              <Loader size="small" loading />
            </Box>
          )}
        </Box>
      )
    } else {
      return (
        <Box>
          <Typography variant="h4" style={titleStyle}>
            {title}
          </Typography>

          <Typography variant="h6" style={styles.subtitle}>
            {subtitle}
          </Typography>
        </Box>
      )
    }
  }

  return (
    <Box sx={styles.container(isContained)} role="form">
      {!title != null && (
        <Box sx={styles.header}>
          {getFormHeaderText()}

          {isContained && !isEditable && (
            <Button
              buttonType="secondary"
              text="Edit"
              disabled={loading}
              isNav
              isActiveNav
              onClick={() => setIsEditable && setIsEditable(true)}
            />
          )}
        </Box>
      )}

      <Box component="form" noValidate onSubmit={formik.handleSubmit} sx={styles.form}>
        {renderFormContent()}

        {customElement && (
          <Grid item xs={12}>
            {customElement}
          </Grid>
        )}

        {getFormButtons()}
      </Box>
    </Box>
  )
}

export default React.memo(Form)
