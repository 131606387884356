import { Box } from '@mui/material'
import { Outlet } from 'react-router-dom'

import styles from './MainLayout.styles'

const MainLayout = () => {
  return (
    <Box sx={styles.container}>
      <Outlet />
    </Box>
  )
}

export default MainLayout
