import { SxProps } from '@mui/material'

import theme from '../../styles/themes/default.theme'

const styles = {
  dialog: {
    '.MuiDialogContent-root': {
      padding: theme.spacing(5)
    },
    '.MuiDialogActions-root': {
      padding: theme.spacing(0),
      paddingTop: theme.spacing(5)
    },
    width: '100%'
  },

  closeButtonContainer: {
    position: 'absolute',
    top: 15,
    right: 15
  },

  closeButton: {
    minWidth: '24px',
    width: '24px',
    height: '24px',
    padding: 0,
    borderRadius: '50%',
    boxShadow: 'none',
    color: theme.palette.primary.contrastText,
    backgroundColor: 'transparent'
  },

  title: {
    color: theme.palette.grey[800],
    textAlign: 'left',
    fontWeight: '500',
    marginBottom: theme.spacing(1),
    flex: 1
  },

  subtitle: (hasTitleRightComponent?: boolean) =>
    ({
      color: theme.palette.grey[500],
      textAlign: 'left',
      fontWeight: '400',
      paddingTop: hasTitleRightComponent ? theme.spacing(2) : theme.spacing(0)
    }) as SxProps,

  headerContainer: {
    display: 'flex',
    flexDirection: 'column'
  },

  iconContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
    fontSize: '80px'
  },

  titleWithIcon: {
    textAlign: 'center'
  },

  baseIcon: {
    fontSize: '80px'
  },

  errorIcon: {
    color: theme.palette.error.main
  },

  warningIcon: {
    color: theme.palette.warning.main
  },

  infoIcon: {
    color: theme.palette.info.main
  },

  successIcon: {
    color: theme.palette.success.main
  },

  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    gap: theme.spacing(1)
  }
}

export default styles
