import { useEffect, useState } from 'react'

import { useLocation } from 'react-router-dom'

import routes from '../../../router/routes.dictionary'

const useFooterState = () => {
  const [isMain, setIsMain] = useState(true)
  const location = useLocation()

  useEffect(() => {
    const mainPaths = [routes.shop.path, routes.home.path.replace('/', '')]
    setIsMain(mainPaths.includes(location.pathname.replace('/', '')))
  }, [location])

  return [isMain]
}

export default useFooterState
