import { configureRequest } from '../../../../networkRequests/apiClient/apiClient.functions'
import urls from '../../../../networkRequests/apiClient/endpoints'
import {
  GetLoyaltyProgramsErrorResponse,
  GetLoyaltyProgramsRequest,
  GetLoyaltyProgramsSuccessResponse
} from './getLoyaltyPrograms.types'

export const getLoyaltyProgramsApi = async (
  request?: GetLoyaltyProgramsRequest
): Promise<GetLoyaltyProgramsSuccessResponse> => {
  const response = await configureRequest({
    url: urls.profile.getLoyaltyPrograms,
    method: 'GET',
    data: request,
    params: {}
  })

  if (response.status >= 200 && response.status < 300) {
    return response as GetLoyaltyProgramsSuccessResponse
  } else {
    throw response as GetLoyaltyProgramsErrorResponse
  }
}

export default getLoyaltyProgramsApi
