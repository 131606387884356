/**
 * Represents the environment configuration object.
 * This object is used to validate and access environment variables.
 */
const env = {
  /**
   * The current environment mode.
   * @remarks
   * Possible values are 'development', 'production', 'test', or 'local'.
   * This variable determines the mode in which the application is running.
   */
  NODE_ENV: import.meta.env?.VITE_NODE_ENV,

  /**
   * Use Mock Data
   * @remarks
   * This variable determines whether to use mock data.
   * Possible values are 'true' or 'false'.
   */
  USE_MOCK: import.meta.env?.VITE_USE_MOCK,

  /**
   * Support Email Address
   * @example "support@appshop.biz"
   * This email address is used for customer support.
   */
  SUPPORT_EMAIL_ADDRESS: import.meta.env?.VITE_SUPPORT_EMAIL_ADDRESS,

  /**
   * Business Name
   * @example "Hourrier, Inc."
   * This name is used in the footer.
   */
  BUSINESS_NAME: import.meta.env?.VITE_BUSINESS_NAME,

  /**
   * Firebase API Key
   * @example "your_firebase_api_key"
   * This key is used to authenticate requests from your app to Firebase services.
   */
  FIREBASE_API_KEY: import.meta.env?.VITE_FIREBASE_API_KEY,

  /**
   * Firebase Auth Domain
   * @example "your_firebase_auth_domain"
   * This domain is used for Firebase Authentication.
   */
  FIREBASE_AUTH_DOMAIN: import.meta.env?.VITE_FIREBASE_AUTH_DOMAIN,

  /**
   * Firebase Project ID
   * @example "your_firebase_project_id"
   * This ID uniquely identifies your Firebase project.
   */
  FIREBASE_PROJECT_ID: import.meta.env?.VITE_FIREBASE_PROJECT_ID,

  /**
   * Firebase Storage Bucket
   * @example "your_firebase_storage_bucket"
   * This bucket is used to store files in Firebase Storage.
   */
  FIREBASE_STORAGE_BUCKET: import.meta.env?.VITE_FIREBASE_STORAGE_BUCKET,

  /**
   * Firebase Messaging Sender ID
   * @example "your_firebase_messaging_sender_id"
   * This ID is used to identify the sender of Firebase Cloud Messages.
   */
  FIREBASE_MESSAGING_SENDER_ID: import.meta.env?.VITE_FIREBASE_MESSAGING_SENDER_ID,

  /**
   * Firebase App ID
   * @example "your_firebase_app_id"
   * This ID uniquely identifies your Firebase app.
   */
  FIREBASE_APP_ID: import.meta.env?.VITE_FIREBASE_APP_ID,

  /**
   * Firebase Measurement ID
   * @example "your_firebase_measurement_id"
   * This ID is used for Google Analytics measurement.
   */
  FIREBASE_MEASUREMENT_ID: import.meta.env?.VITE_FIREBASE_MEASUREMENT_ID,

  /**
   * API Base URL
   * @example "http://localhost:3000/api/"
   * This URL is the base endpoint for your API requests.
   */
  API_BASE_URL: import.meta.env?.VITE_API_BASE_URL,

  /**
   * Web App URL
   * @example "http://localhost:3000"
   * This URL is the base endpoint for your web app.
   */
  WEB_APP_URL: import.meta.env?.VITE_WEB_APP_URL,

  /**
   * Sentry Organization
   * @example "your_sentry_org"
   * This variable is used to identify the Sentry organization.
   */
  SENTRY_ORG: import.meta.env?.VITE_SENTRY_ORG,

  /**
   * Sentry Project
   * @example "your_sentry_project"
   * This variable is used to identify the Sentry project.
   */
  SENTRY_PROJECT: import.meta.env?.VITE_SENTRY_PROJECT,

  /**
   * Sentry Script URL
   * @example "https://js.sentry-cdn.com/479fcfcaf3a91f7a91ab8b9fb64c7c2a.min.js"
   * This URL is the base endpoint for your Sentry script.
   */
  SENTRY_SCRIPT_URL: import.meta.env?.VITE_SENTRY_SCRIPT_URL,

  /**
   * Sentry DSN
   * @example "your_sentry_dsn"
   * This DSN (Data Source Name) is used to configure and authenticate the application with Sentry.
   */
  SENTRY_DSN: import.meta.env?.VITE_SENTRY_DSN,

  /**
   * Sentry Auth Token
   * @example "your_sentry_auth_token"
   * This token is used to authenticate the application with Sentry.
   */
  SENTRY_AUTH_TOKEN: import.meta.env?.VITE_SENTRY_AUTH_TOKEN,

  /**
   * Stripe Publishable Key
   * @example "pk_test_1234567890abcdef1234567890abcdef"
   * This key is used to authenticate requests from your app to Stripe.
   */
  STRIPE_PUBLISHABLE_KEY: import.meta.env?.VITE_STRIPE_PUBLISHABLE_KEY,

  /**
   * Enable Usersnap
   * @example "true"
   * This variable determines whether to enable Usersnap.
   */
  USERSNAP_ENABLED: import.meta.env?.VITE_USERSNAP_ENABLED,

  /**
   * Usersnap Script URL
   * @example "https://widget.usersnap.com/global/load/56cf5855-4d33-4926-839f-b2bb23d6f599?onload=onUsersnapLoad"
   * This URL is used to load the Usersnap script.
   */
  USERSNAP_SCRIPT_URL: import.meta.env?.VITE_USERSNAP_SCRIPT_URL,

  /**
   * Loyalty Program Modal Display Limit
   * @example "3"
   * This variable determines the number of times the loyalty program modal will be displayed when the user does not have a loyalty program.
   */
  LOYALTY_PROGRAM_MODAL_DISPLAY_LIMIT: import.meta.env?.VITE_LOYALTY_PROGRAM_MODAL_DISPLAY_LIMIT,

  /**
   * Passport Child Age Limit
   * @example "18"
   * This variable determines the age limit for a passenger to be considered a child.
   */
  PASSPORT_CHILD_AGE_LIMIT: import.meta.env?.VITE_PASSPORT_CHILD_AGE_LIMIT
}

export default env
