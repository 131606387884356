import React from 'react'

import { Box, Typography } from '@mui/material'

import Button from '../../../../shared/components/Button/Button.component'
import Loader from '../../../../shared/components/Loader/Loader.component'

import { styles } from './ProfileActionCard.styles'
import { ProfileActionCardProps } from './ProfileActionCard.types'

const ProfileActionCard: React.FC<ProfileActionCardProps> = ({ header, children, loading, loadingText }) => {
  const renderHeader = () => {
    if (!header?.title) {
      return null
    }

    return (
      <Box sx={styles.header}>
        {header?.title && (
          <Typography variant="h6" sx={styles.title}>
            {header.title}
          </Typography>
        )}

        {header?.buttons && (
          <Box sx={styles.buttonContainer}>
            {header.buttons.map((button, index) => (
              <Button
                key={`${button.text}-${index}`}
                buttonType={button.buttonType || 'primary'}
                outline={button.outline}
                text={button.text}
                onClick={button.onClick}
              />
            ))}
          </Box>
        )}
      </Box>
    )
  }

  return (
    <Box sx={styles.container}>
      {renderHeader()}

      <Box sx={{ ...styles.body, ...(loading && { justifyContent: 'center' }) }}>
        {loading ? <Loader size="small" loading={loading} text={loadingText} /> : children}
      </Box>
    </Box>
  )
}

export default ProfileActionCard
