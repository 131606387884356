export const acceptItemRequestDictionary = {
  customsLimitExceeded: {
    issue: 'customs_limit_exceeded',
    title: 'Customs Limit Exceeded'
  },
  noCustomsLimitFound: {
    issue: 'no_customs_limit_found',
    title: 'No Customs Limit Found'
  }
}
