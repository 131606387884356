export const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%'
  },

  programContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '16px',
    marginBottom: '8px',
    borderRadius: '8px',
    border: '1px solid #e0e0e0'
  },

  airlineName: {
    fontWeight: 'bold'
  },

  accountNumber: {
    color: 'gray',
    fontSize: '0.875rem'
  }
}
