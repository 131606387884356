import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import urls from '../../../../networkRequests/apiClient/endpoints'
import errorHandler from '../../../../shared/functions/ErrorHandler/errorHandler.functions'
import { ApiErrorResponse } from '../../../../shared/types/api.types'
import { commitPurchasePriceApi } from './commitPurchasePrice.api'
import commitPurchasePriceInitialState from './commitPurchasePrice.initialState'
import { CommitPurchasePriceErrorResponse, CommitPurchasePriceRequestPayload } from './commitPurchasePrice.types'

export const commitPurchasePrice = createAsyncThunk(
  urls.items.commitPurchase,
  async (payload: CommitPurchasePriceRequestPayload, { rejectWithValue }) => {
    const { request, onSuccess, onError } = payload

    try {
      const response = await commitPurchasePriceApi(request)

      onSuccess && onSuccess()

      return response
    } catch (error) {
      const errorResponse = error as ApiErrorResponse

      const stripeConnectAccountError = errorResponse.details?.find((detail) => {
        const isStripeConnectAccountError = detail.field === 'stripeConnectAccount'
        const isNotOnboarded = detail.issue === 'notOnboarded'
        const isNotFound = detail.issue === 'notFound'

        return isStripeConnectAccountError && (isNotFound || isNotOnboarded)
      })

      if (stripeConnectAccountError) {
        onError && onError()
      } else {
        errorHandler.handleApiError(error as ApiErrorResponse, undefined, true)

        return rejectWithValue(error)
      }
    }
  }
)

const commitPurchasePriceSlice = createSlice({
  name: urls.items.commitPurchase,
  initialState: commitPurchasePriceInitialState,
  reducers: {
    resetCommitPurchasePrice: (state) => {
      state.loading = false
      state.error = null
      state.success = null
    }

    // Add any additional reducers here
  },
  extraReducers: (builder) => {
    builder
      .addCase(commitPurchasePrice.pending, (state) => {
        state.loading = true
        state.error = null
        state.success = null
      })
      .addCase(commitPurchasePrice.fulfilled, (state, { payload }) => {
        state.loading = false
        state.success = payload ?? null
        state.error = null
      })
      .addCase(commitPurchasePrice.rejected, (state, { payload }) => {
        state.loading = false
        state.error = payload as CommitPurchasePriceErrorResponse
        state.success = null
      })
  }
})

export const { actions: commitPurchasePriceActions, reducer: commitPurchasePriceReducer } = commitPurchasePriceSlice
export const { resetCommitPurchasePrice } = commitPurchasePriceActions
export default commitPurchasePriceSlice.reducer
