import React from 'react'

import { Box } from '@mui/material'

import styles from './ResponsiveDisplayWrapper.styles'
import { ResponsiveDisplayWrapperProps } from './ResponsiveDisplayWrapper.types'

const ResponsiveDisplayWrapper: React.FC<ResponsiveDisplayWrapperProps> = ({ isDesktop, children, style }) => {
  const combinedStyles = {
    ...(isDesktop ? styles.desktop : styles.mobile),
    ...(style ?? {})
  }

  return <Box sx={combinedStyles}>{children}</Box>
}

export default ResponsiveDisplayWrapper
