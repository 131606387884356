import { SxProps } from '@mui/material'

import theme from '../../shared/styles/themes/default.theme'
import { mobileXPadding, mobileYPadding } from './MainLayout.constants'

const styles = {
  container: {
    padding: 'unset',
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(mobileYPadding, mobileXPadding)
    }
  } as SxProps
}

export default styles
