import React from 'react'

import { InfoOutlined } from '@mui/icons-material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMoreOutlined'
import {
  AccordionDetails,
  AccordionSlots,
  AccordionSummary,
  Box,
  Divider,
  Fade,
  Grid,
  Accordion as MuiAccordion,
  Typography
} from '@mui/material'

import Button from '../Button/Button.component'

import { ButtonOnClick } from '../Button/Button.types'
import styles from './Accordion.styles'
import { AccordionProps } from './Accordion.types'

const Accordion: React.FC<AccordionProps> = (props) => {
  const {
    header,
    body,
    footer,
    alert,
    expandIconPosition = 'left',
    noExpandIcon = false,
    noExpandBackgroundColor = false,
    isExpanded = false,
    preventExpandChange = false,
    handleExpansion,
    accordionKey
  } = props

  const [expanded, setExpanded] = React.useState(isExpanded)

  const handleAccordionExpansion = () => {
    setExpanded((prevExpanded) => {
      handleExpansion && handleExpansion(!prevExpanded, accordionKey)

      return !prevExpanded
    })
  }

  const handleButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    onClick: ButtonOnClick,
    expandAccordion: boolean
  ) => {
    event.stopPropagation()
    onClick()

    if (expandAccordion) {
      handleAccordionExpansion()
    }
  }

  const renderFooter = () => {
    let footerContent = null
    let content = null
    let mainContent = null
    let buttons = null

    if (footer) {
      mainContent = footer.mainContent
      buttons = footer.buttons
    } else if (alert) {
      mainContent = alert.message
      buttons = alert.buttons
    }

    if (footer || alert) {
      content = (
        <Box sx={styles.accordionFooterContent(!!alert)}>
          {footer ? (
            mainContent
          ) : (
            <Typography sx={styles.accordionFooterAlertText(alert?.type)}>
              <InfoOutlined sx={styles.accordionFooterAlertIcon} /> {mainContent}
            </Typography>
          )}

          <Box sx={styles.accordionFooterButtons}>
            {buttons?.map((buttonProps) => (
              <Button
                {...buttonProps}
                style={styles.accordionFooterButton(alert?.type)}
                onClick={(event) =>
                  handleButtonClick(
                    event as React.MouseEvent<HTMLButtonElement>,
                    buttonProps.onClick as ButtonOnClick,
                    buttonProps?.expandAccordion || false
                  )
                }
              />
            ))}
          </Box>
        </Box>
      )
    }

    if (footer || alert) {
      footerContent = (
        <Box sx={styles.accordionFooterContainer(alert?.type)}>
          <Divider sx={styles.accordionFooterDivider} />
          <Box>{content}</Box>
        </Box>
      )
    }

    return footerContent
  }

  return (
    <Box sx={styles.accordionContainer(!!footer, alert?.type)} id={'accordion-container'}>
      <MuiAccordion
        style={styles.accordionContent(isExpanded && !!footer)}
        expanded={expanded}
        onChange={preventExpandChange ? undefined : handleAccordionExpansion}
        slots={{ transition: Fade as AccordionSlots['transition'] }}
        slotProps={{ transition: { timeout: 400 } }}
        sx={styles.accordionContainerSx(expanded, isExpanded && !!alert, props.maxWidth)}
        id={'mui-accordion'}
      >
        <AccordionSummary
          expandIcon={noExpandIcon ? undefined : <ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          sx={styles.accordionHeader(expandIconPosition, noExpandBackgroundColor)}
        >
          <Grid container sx={styles.accordionHeaderContent}>
            <Grid item md={header.buttons || header.secondaryContent ? 8 : 12} sx={styles.accordionHeaderMainContent}>
              {header.mainContent}
            </Grid>

            {(header.buttons || header.secondaryContent) && (
              <Grid item md={4} sx={styles.accordionHeaderButtons}>
                {header.buttons?.map((buttonProps) => (
                  <Button
                    {...buttonProps}
                    onClick={(event) =>
                      handleButtonClick(
                        event as React.MouseEvent<HTMLButtonElement>,
                        buttonProps.onClick as ButtonOnClick,
                        buttonProps?.expandAccordion || false
                      )
                    }
                  />
                ))}

                {header.secondaryContent}
              </Grid>
            )}
          </Grid>
        </AccordionSummary>

        <AccordionDetails sx={styles.accordionDetails}>{body}</AccordionDetails>
      </MuiAccordion>

      {renderFooter()}
    </Box>
  )
}

export default Accordion
