import { configureRequest } from '../../../../networkRequests/apiClient/apiClient.functions'
import urls from '../../../../networkRequests/apiClient/endpoints'
import {
  UpdateLoyaltyProgramErrorResponse,
  UpdateLoyaltyProgramRequest,
  UpdateLoyaltyProgramSuccessResponse
} from './updateLoyaltyProgram.types'

export const updateLoyaltyProgramApi = async (
  request: UpdateLoyaltyProgramRequest
): Promise<UpdateLoyaltyProgramSuccessResponse> => {
  const response = await configureRequest({
    url: urls.profile.updateLoyaltyProgram,
    method: 'PUT',
    data: {
      airline_iata_code: request.airlineIataCode,
      account_number: request.accountNumber
    },
    params: {
      id: request.loyaltyProgramId
    }
  })

  if (response.status >= 200 && response.status < 300) {
    return response as UpdateLoyaltyProgramSuccessResponse
  } else {
    throw response as UpdateLoyaltyProgramErrorResponse
  }
}

export default updateLoyaltyProgramApi
