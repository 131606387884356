import { SxProps } from '@mui/material'

import theme from '../../styles/themes/default.theme'

const styles = {
  modalContainer: {
    display: 'flex',
    flexDirection: 'column',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    maxWidth: 800,
    maxHeight: '90vh',
    backgroundColor: theme.palette.background.paper,
    // boxShadow: theme.shadows[5],
    padding: theme.spacing(4),
    borderRadius: theme.shape.borderRadius / 2,
    overflow: 'auto'
  } as SxProps,

  title: {
    fontWeight: theme.typography.fontWeightBold,
    marginBottom: theme.spacing(2)
  } as SxProps,

  formContainer: {
    width: '100%',
    marginTop: theme.spacing(2)
  } as SxProps
}

export default styles
