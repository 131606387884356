import { CSSProperties } from 'react'

import { SxProps } from '@mui/material'

import theme from '../../styles/themes/default.theme'

export const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%'
  },

  bottomContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    gap: theme.spacing(3),
    padding: theme.spacing(5, 10)
  } as SxProps,

  main: {
    display: 'flex',
    flexDirection: 'column'
  } as SxProps,

  titleContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: theme.spacing(2),
    justifyContent: 'flex-start',
    alignItems: 'flex-start'
  } as SxProps,

  titleAndFilterContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingBottom: theme.spacing(3)
  } as SxProps,

  title: {
    color: theme.palette.grey[800],
    fontWeight: theme.typography.fontWeightBold,
    lineHeight: '32px',
    textAlign: 'left',
    marginBottom: theme.spacing(1)
  } as SxProps,

  subtitle: {
    color: theme.palette.grey[500],
    fontWeight: theme.typography.fontWeightRegular,
    lineHeight: '16px',
    textAlign: 'left'
  } as SxProps,

  infiniteScroll: {
    overflow: 'visible'
  } as CSSProperties,

  endOfScrollMessage: {
    textAlign: 'center',
    my: 2
  } as SxProps,

  listContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    flex: 1
  } as SxProps,

  filterTabsContainer: {
    display: 'flex',
    marginBottom: theme.spacing(2),
    borderBottom: `1px solid ${theme.palette.grey[300]}`
  },

  orderListTabs: {
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    alignSelf: 'flex-end'
  },

  tab: {
    display: 'flex',
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.text.secondary, // Grey text color for inactive tabs
    textTransform: 'none',
    '&.Mui-selected': {
      color: theme.palette.primary.main // Active tab color
    }
  } as SxProps,

  tabIndicator: {
    backgroundColor: theme.palette.primary.main // Active tab underline color
  },

  tabRoot: {
    '& .MuiTabs-indicator': {
      backgroundColor: theme.palette.primary.main // Active tab underline color
    },
    '& .MuiTab-root': {
      borderBottom: `5px solid ${theme.palette.grey[300]}` // Grey underline for inactive tabs
    },
    '& .Mui-selected': {
      borderBottom: `5px solid ${theme.palette.primary.main}` // Primary underline for active tab
    }
  } as CSSProperties,

  searchBarContainer: {
    display: 'flex'
  } as SxProps,

  sidebar: {
    maxWidth: '250px',
    minWidth: '250px',
    marginRight: theme.spacing(3)
  } as SxProps,

  masonryContainer: {
    width: '100%'
  } as SxProps
}
