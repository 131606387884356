import React from 'react'

import { Box, Divider, Typography } from '@mui/material'

import ResponsiveDisplayWrapper from '../../../../shared/components/ResponsiveDisplayWrapper/ResponsiveDisplayWrapper.component'

import date, { dateTimeFormats } from '../../../../shared/functions/Date/date.functions'
import useResponsiveness from '../../../../shared/hooks/responsive.hooks'
import styles from './MiniTripDetails.styles'
import { MiniTripDetailsProps } from './MiniTripDetails.types'

const MiniTripDetails: React.FC<MiniTripDetailsProps> = ({ flightItinerary }) => {
  const [isDesktop] = useResponsiveness()

  return (
    <Box sx={styles.container}>
      <Typography variant="h6" sx={styles.title}>
        Trip Details
      </Typography>

      <Box sx={styles.tripDetails}>
        {flightItinerary.itinerary.legs.map((leg, index) => (
          <Box key={index} sx={styles.legContainer}>
            <Box sx={styles.legDetails}>
              <Typography variant="h6" sx={styles.route}>
                {isDesktop
                  ? `${leg.origin.city.name} → ${leg.destination.city.name}`
                  : `${leg.origin.city.iataCode} → ${leg.destination.city.iataCode}`}
              </Typography>

              <Typography variant="body2" sx={styles.arrivalLabel}>
                Arrives on&nbsp;
                <Typography variant="body2" sx={styles.arrivalDate}>
                  {date(leg.travelDate).format(dateTimeFormats.date.medium)}
                </Typography>
              </Typography>

              <ResponsiveDisplayWrapper isDesktop={false} style={styles.separatorWrapper}>
                {index < flightItinerary.itinerary.legs.length - 1 && <Divider sx={styles.separator} />}
              </ResponsiveDisplayWrapper>
            </Box>

            <ResponsiveDisplayWrapper isDesktop={true}>
              {index < flightItinerary.itinerary.legs.length - 1 && <Divider sx={styles.separator} />}
            </ResponsiveDisplayWrapper>
          </Box>
        ))}
      </Box>
    </Box>
  )
}

export default MiniTripDetails
