import { configureStore } from '@reduxjs/toolkit'

import changePasswordReducer from '../states/auth/changePassword/changePassword.slice'
import forgetPasswordReducer from '../states/auth/forgetPassword/forgetPassword.slice'
import loginReducer from '../states/auth/login/login.slice'
import logoutReducer from '../states/auth/logout/logout.slice'
import resetForgottenPasswordReducer from '../states/auth/resetForgottenPassword/resetForgottenPassword.slice'
import signUpReducer from '../states/auth/signUp/signUp.slice'
import verifyEmailReducer from '../states/auth/verifyEmail/verifyEmail.slice'
import firebaseRemoteConfigReducer from '../states/firebaseRemoteConfig/firebaseRemoteConfig.slice'
import cancelFlightItineraryReducer from '../states/flights/cancelFlightItinerary/cancelFlightItinerary.slice'
import createFlightBookingReducer from '../states/flights/createFlightBooking/createFlightBooking.slice'
import { createFlightItineraryReducer } from '../states/flights/createFlightItinerary/createFlightItinerary.slice'
import getAllFlightItinerariesReducer from '../states/flights/getAllFlightItineraries/getAllFlightItineraries.slice'
import getBaggageDetailsReducer from '../states/flights/getBaggageDetails/getBaggageDetails.slice'
import getCountriesAndCitiesReducer from '../states/flights/getCountriesAndCities/getCountriesAndCities.slice'
import { getFlightFaresReducer } from '../states/flights/getFlightFares/getFlightFares.slice'
import { getFlightItineraryReducer } from '../states/flights/getFlightItinerary/getFlightItinerary.slice'
import getFlightItineraryShareLinkReducer from '../states/flights/getFlightItineraryShareLink/getFlightItineraryShareLink.slice'
import getFlightPlacesReducer from '../states/flights/getFlightPlaces/getFlightPlaces.slice'
import { getNextFlightsReducer } from '../states/flights/getNextFlights/getNextFlights.slice'
import getSeatDetailsReducer from '../states/flights/getSeatDetails/getSeatDetails.slice'
import getUpdatedFlightOfferReducer from '../states/flights/getUpdatedFlightOffer/getUpdatedFlightOffer.slice'
import searchFlightsReducer from '../states/flights/searchFlights/searchFlights.slice'
import flightSearchWidgetReducer from '../states/flights/searchWidget/flightSearchWidget.reducer'
import acceptItemPriceChangeReducer from '../states/items/acceptItemPriceChange/acceptItemPriceChange.slice'
import commitPurchasePriceReducer from '../states/items/commitPurchasePrice/commitPurchasePrice.slice'
import getItemChangesReducer from '../states/items/getItemChanges/getItemChanges.slice'
import { getItemDetailsReducer } from '../states/items/getItemDetails/getItemDetails.slice'
import { acceptPublicOfferReducer } from '../states/offerRequest/acceptPublicOffer/acceptPublicOffer.slice'
import { getPublicOffersReducer } from '../states/offerRequest/getPublicOffers/getPublicOffers.slice'
import acceptItemRequestReducer from '../states/offers/acceptItemRequest/acceptItemRequest.slice'
import addItemTrackingNumberReducer from '../states/offers/addItemTrackingNumber/addItemTrackingNumber.slice'
import cancelItemRequestReducer from '../states/offers/cancelItemRequest/cancelItemRequest.slice'
import { createOfferRequestReducer } from '../states/offers/createOfferRequest/createOfferRequest.slice'
import denyItemRequestReducer from '../states/offers/denyItemRequest/denyItemRequest.slice'
import getItemRequestTrackingDetailsReducer from '../states/offers/getItemRequestTrackingDetails/getItemRequestTrackingDetails.slice'
import getOfferRequestsReducer from '../states/offers/getOfferRequests/getOfferRequests.slice'
import reassignItemRequestReducer from '../states/offers/reassignItemRequest/reassignItemRequest.slice'
import releaseOfferRequestReducer from '../states/offers/releaseOfferRequest/releaseOfferRequest.slice'
import resetOfferRequestFlightItineraryReducer from '../states/offers/resetOfferRequestFlightItinerary/resetOfferRequestFlightItinerary.slice'
import updateItemRequestTrackingDetailsReducer from '../states/offers/updateItemRequestTrackingDetails/updateItemRequestTrackingDetails.slice'
import createAccountSessionReducer from '../states/payments/createAccountSession/createAccountSession.slice'
import createCustomerSessionReducer from '../states/payments/createCustomerSession/createCustomerSession.slice'
import createFlightBookingPaymentIntentReducer from '../states/payments/createFlightBookingPaymentIntent/createFlightBookingPaymentIntent.slice'
import createOfferRequestPaymentIntentReducer from '../states/payments/createOfferRequestPaymentIntent/createOfferRequestPaymentIntent.slice'
import logPaymentMethodUpdateReducer from '../states/payments/logPaymentMethodUpdate/logPaymentMethodUpdate.slice'
import requestOfferRequestRefundReducer from '../states/payments/requestOfferRequestRefund/requestOfferRequestRefund.slice'
import addDeliveryAddressReducer from '../states/profile/addDeliveryAddress/addDeliveryAddress.slice'
import { addLoyaltyProgramReducer } from '../states/profile/addLoyaltyProgram/addLoyaltyProgram.slice'
import { deleteLoyaltyProgramReducer } from '../states/profile/deleteLoyaltyProgram/deleteLoyaltyProgram.slice'
import { getLoyaltyProgramsReducer } from '../states/profile/getLoyaltyPrograms/getLoyaltyPrograms.slice'
import getProfileReducer from '../states/profile/getProfile/getProfile.slice'
import updateContactInfoReducer from '../states/profile/updateContactInfo/updateContactInfo.slice'
import updateDeliveryAddressReducer from '../states/profile/updateDeliveryAddress/updateDeliveryAddress.slice'
import { updateLoyaltyProgramReducer } from '../states/profile/updateLoyaltyProgram/updateLoyaltyProgram.slice'
import updatePersonalInfoReducer from '../states/profile/updatePersonalInfo/updatePersonalInfo.slice'
import { getSupportedLoyaltyProgramsReducer } from '../states/supportedLoyaltyProgram/getSupportedLoyaltyPrograms/getSupportedLoyaltyPrograms.slice'

export default configureStore({
  reducer: {
    commitPurchasePrice: commitPurchasePriceReducer,
    acceptItemPriceChange: acceptItemPriceChangeReducer,
    acceptItemRequest: acceptItemRequestReducer,
    acceptPublicOffer: acceptPublicOfferReducer,
    addDeliveryAddress: addDeliveryAddressReducer,
    addItemTrackingNumber: addItemTrackingNumberReducer,
    addLoyaltyProgram: addLoyaltyProgramReducer,
    cancelFlightItinerary: cancelFlightItineraryReducer,
    cancelItemRequest: cancelItemRequestReducer,
    changePassword: changePasswordReducer,
    createAccountSession: createAccountSessionReducer,
    createFlightBooking: createFlightBookingReducer,
    createFlightBookingPaymentIntent: createFlightBookingPaymentIntentReducer,
    createFlightItinerary: createFlightItineraryReducer,
    createCustomerSession: createCustomerSessionReducer,
    createOfferRequest: createOfferRequestReducer,
    createOfferRequestPaymentIntent: createOfferRequestPaymentIntentReducer,
    deleteLoyaltyProgram: deleteLoyaltyProgramReducer,
    denyItemRequest: denyItemRequestReducer,
    firebaseRemoteConfig: firebaseRemoteConfigReducer,
    flightSearchWidget: flightSearchWidgetReducer,
    forgetPassword: forgetPasswordReducer,
    getAllFlightItineraries: getAllFlightItinerariesReducer,
    getBaggageDetails: getBaggageDetailsReducer,
    getCountriesAndCities: getCountriesAndCitiesReducer,
    getFlightItinerary: getFlightItineraryReducer,
    getFlightItineraryShareLink: getFlightItineraryShareLinkReducer,
    getFlightFares: getFlightFaresReducer,
    getFlightPlaces: getFlightPlacesReducer,
    getItemChanges: getItemChangesReducer,
    getItemDetails: getItemDetailsReducer,
    getItemRequestTrackingDetails: getItemRequestTrackingDetailsReducer,
    getLoyaltyPrograms: getLoyaltyProgramsReducer,
    getNextFlights: getNextFlightsReducer,
    getOfferRequests: getOfferRequestsReducer,
    getProfile: getProfileReducer,
    getPublicOffers: getPublicOffersReducer,
    getSeatDetails: getSeatDetailsReducer,
    getSupportedLoyaltyPrograms: getSupportedLoyaltyProgramsReducer,
    getUpdatedFlightOffer: getUpdatedFlightOfferReducer,
    login: loginReducer,
    logout: logoutReducer,
    logPaymentMethodUpdate: logPaymentMethodUpdateReducer,
    reassignItemRequest: reassignItemRequestReducer,
    releaseOfferRequest: releaseOfferRequestReducer,
    requestOfferRequestRefund: requestOfferRequestRefundReducer,
    resetForgottenPassword: resetForgottenPasswordReducer,
    resetOfferRequestFlightItinerary: resetOfferRequestFlightItineraryReducer,
    searchFlights: searchFlightsReducer,
    signUp: signUpReducer,
    updateContactInfo: updateContactInfoReducer,
    updateDeliveryAddress: updateDeliveryAddressReducer,
    updateItemRequestTrackingDetails: updateItemRequestTrackingDetailsReducer,
    updateLoyaltyProgram: updateLoyaltyProgramReducer,
    updatePersonalInfo: updatePersonalInfoReducer,
    verifyEmail: verifyEmailReducer
  }
})
