import React, { useCallback, useEffect, useState } from 'react'

import { Box, Grid } from '@mui/material'

import { EmptyState } from '../EmptyState/EmptyState.component'
import Loader from '../Loader/Loader.component'
import MainPageSection from '../MainPageSection/MainPageSection.component'
import OfferDealItem from './components/OfferDealItem.component'

import { isAuthenticated } from '../../../networkRequests/apiClient/apiClient.functions'
import { getAllFlightItineraries } from '../../../redux/states/flights/getAllFlightItineraries/getAllFlightItineraries.slice'
import {
  FlightItinerary,
  GetAllFlightItinerariesRequest
} from '../../../redux/states/flights/getAllFlightItineraries/getAllFlightItineraries.types'
import router from '../../../router/functions/router.functions'
import routes from '../../../router/routes.dictionary'
import { useAppDispatch } from '../../../shared/hooks/redux.hooks'
import styles from './FlightDealsCarousel.styles'
import { FlightDealsCarouselProps } from './FlightDealsCarousel.types'

const FlightDealsCarousel: React.FC<FlightDealsCarouselProps> = ({ deals, onRefresh, isLoadingDeals }) => {
  const navigate = router.navigate()
  const dispatch = useAppDispatch()
  const [flightItineraries, setFlightItineraries] = useState<FlightItinerary[]>([])

  useEffect(() => {
    const fetchFlightItineraries = async () => {
      if (!isAuthenticated()) return

      const getAllFlightItinerariesPayload: GetAllFlightItinerariesRequest = {}

      const response = await dispatch(
        getAllFlightItineraries({
          request: getAllFlightItinerariesPayload
        })
      ).unwrap()

      setFlightItineraries(response.data.results)
    }

    fetchFlightItineraries()
  }, [dispatch])

  const handleExplore = useCallback(() => {
    navigate(routes.offers.path)
    window.scrollTo(0, 0)
  }, [navigate])

  return (
    <Box sx={styles.container}>
      <MainPageSection
        title="Flight Deals"
        subtitle="Browse orders from these cities to see where you will fly next."
        button={{
          text: 'Explore',
          onClick: handleExplore,
          buttonType: 'tertiary',
          large: true
        }}
      >
        {isLoadingDeals ? (
          <Box sx={styles.loaderContainer}>
            <Loader text="Getting Flight Deals" loading={isLoadingDeals} />
          </Box>
        ) : (
          <>
            {deals.length > 0 ? (
              <Grid container spacing={4} sx={styles.dealsContainer}>
                {deals.map((deal) => (
                  <Grid item xs={12} sm={6} md={4} key={deal.offerRequestId}>
                    <OfferDealItem deal={deal} flightItineraries={flightItineraries} />
                  </Grid>
                ))}
              </Grid>
            ) : (
              <EmptyState
                title="No deals found"
                subtitle="There are no deals available at the moment. Please check back later."
                button={{
                  text: 'Refresh',
                  onClick: onRefresh
                }}
              />
            )}
          </>
        )}
      </MainPageSection>
    </Box>
  )
}

export default FlightDealsCarousel
