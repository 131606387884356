import { CSSProperties } from 'react'

import theme from '../../styles/themes/default.theme'

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(4),
    borderRadius: theme.shape.borderRadius / 4,
    width: '80%',
    marginTop: theme.spacing(10),
    [theme.breakpoints.down('md')]: {
      paddingX: theme.spacing(0)
    }
  } as CSSProperties,

  iconContainer: {
    backgroundColor: theme.palette.success.main,
    borderRadius: '100%',
    padding: '1rem',
    width: '80px',
    height: '80px'
  } as CSSProperties,

  icon: {
    width: '48px',
    height: '48px',
    color: 'white'
  } as CSSProperties,

  title: {
    fontSize: theme.typography.h4.fontSize,
    fontWeight: theme.typography.h4.fontWeight,
    marginBottom: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.grey[900]
  } as CSSProperties,

  description: {
    fontSize: theme.typography.body1.fontSize,
    color: theme.palette.grey[500],
    marginBottom: theme.spacing(5),
    textAlign: 'center'
  } as CSSProperties,

  emptyStateContainer: {
    width: '100%'
  } as CSSProperties
}

export default styles
