import React from 'react'

import Close from '@mui/icons-material/Close'
import { Box, Chip as MuiChip, Stack, Tooltip } from '@mui/material'

import { truncateString } from '../../functions/String/string.functions'
import styles from './Chip.styles'
import { ChipProps } from './Chip.types'

export const Chip: React.FC<ChipProps> = ({
  error,
  showCloseIcon = true,
  isCompact = false,
  tooltipText,
  ...props
}) => {
  const { label, sx } = props

  const chipStyles = { ...(error ? styles.errorChip : styles.chip(isCompact)), ...sx }

  const chipContent = (
    <MuiChip
      {...props}
      onDelete={props.onDelete}
      color={error?.errorBorder ? 'error' : 'default'}
      sx={chipStyles}
      deleteIcon={showCloseIcon ? <Close sx={styles.chipCloseIcon} /> : <></>}
      label={
        <>
          <Stack direction={'row'}>
            {label && (
              <span className="label-text">
                <Box sx={error ? styles.errorChipText : styles.chipText(isCompact)}>
                  {truncateString(label as string, 50)}
                </Box>
              </span>
            )}

            {error && error.text && !error.hideErrorText && (
              <Box component={'span'} sx={styles.errorText} className="error-text">
                {truncateString(error.text, error?.maxLength ?? 30)}
              </Box>
            )}
          </Stack>
        </>
      }
    />
  )

  if (tooltipText) {
    return (
      <Tooltip title={tooltipText} arrow>
        {chipContent}
      </Tooltip>
    )
  } else {
    return chipContent
  }
}
