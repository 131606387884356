import useMediaQuery from '@mui/material/useMediaQuery'

import theme from '../styles/themes/default.theme'

const useResponsiveness = () => {
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'))

  return [isDesktop]
}

export default useResponsiveness
